import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import showPassImg from '../../assets/images/showPass.svg';
import hidePass from '../../assets/images/hidePass.svg';
import logo from '../../assets/images/logo.png';
// import google from '../../imgs/google.png';
import twitter from '../../assets/images/twitter.png';
import Login from "./login";
import './index.css'
import emailIco from '../../assets/images/emailIco.svg';
import passwordIco from '../../assets/images/passwordIco.svg';
import { getUserBalance, loginUser } from "../../redux/ActionCreators";
import { showToast } from "../../App";
import { useDispatch } from "react-redux";

export default function SignupBtn(){
  const [signupModal, setSignupModal] = useState(false);
  const [showPass1, setShowPass1] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
	const dispatch = useDispatch();
	
	const signup = async () => {
		let email = document.getElementById('sign-up-email').value;
		let uname = document.getElementById('sign-up-uname').value;
		let pwd = document.getElementById('sign-up-pwd').value;
		var expression = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
		if (expression.test(email)){
			fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/register`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email: email, pwd: pwd, uname: uname })
			}).then(r => r.json())
			.then(res => {
				if (res.success){
					res.user.token = res.tok;
					dispatch(loginUser(res.user));
					dispatch(getUserBalance(res.user));
				}
				else{
					showToast(res.msg);
				}
			})			
			setSignupModal(false);
		}
		else{
			showToast('Invalid email address');
		}
	}
  return(
    <>
			<button className="themeBtn1" onClick={() => {setSignupModal(true);setShowLogin(true)}} >
				Sign in &#47; Sign up
			</button>
			<Modal id="WalletModal" className="signModal" show={signupModal} onHide={() => setSignupModal(false)} centered >
				<Modal.Body>
					<div className="logo"><img className="img-fluid" src={logo} alt="" /></div>
					<div className="navigate">
						<button className={showLogin ?"active":""}  onClick={()=>setShowLogin(true)}>Sign in</button>
						<button className={showLogin ?"":"active"}  onClick={()=>setShowLogin(false)}>Sign up</button>
					</div>
					{showLogin ?
					<Login setSignupModal={setSignupModal} setShowLogin={setShowLogin} />
					:
					<div>
						<div>
							<div className="inputMain">
								<img className="ico" src={emailIco} alt="" />
								<input type="text" id="sign-up-email" placeholder="Email..." />
							</div>
							<div className="inputMain">
								<img className="ico" src={passwordIco} alt="" />
								<input type="text" id="sign-up-uname" placeholder="Username..." />
							</div>
							<div className="inputMain">
								<i class="fa-solid fa-lock ico"></i>
								<div className="passInput w-100">
									<input type={showPass1?"text":"password"} id="sign-up-pwd" placeholder="Password..." />
									{showPass1?
									<img onClick={()=>setShowPass1(false)} src={showPassImg} alt="" />
									:
									<img onClick={()=>setShowPass1(true)} src={hidePass} alt="" />
									}
								</div>
							</div>
							<div className="btnDiv my-3">
								<button className="themeBtn w-100" onClick={signup} >
									Continue
								</button>
							</div>
							<div className="or">
								<span></span>
								<div className="px-2">Or</div>
								<span></span>
							</div>
							{/* <div className="inputMain">
								<img src={google} alt="" className="ico me-3" />
								<div className="text-center w-100">
									Continue with Google
								</div>
							</div> */}
							<div className="inputMain logWith">
								<img src={twitter} alt="" className="ico me-3" />
								<div className="text-center w-100">
									Continue with X
								</div>
							</div>
							<div className="already">Already have an account? <span onClick={()=>setShowLogin(true)}>Sign In</span></div>
						</div>
						<div className="closeIcon" onClick={() => setSignupModal(false)}>
						<i className="fas fa-times"></i>
						</div>
					</div>
					}

				</Modal.Body>
			</Modal>
    </>
  );
}