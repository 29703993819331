import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { Home } from './pages/home/home'; 
import { Navbar } from './component/navbar';
import { Footer } from './component/footer';
import { TeamDetail } from './pages/teamDetail/detail';
import { toast, ToastContainer } from 'react-toastify';
import { createStore } from './redux/configureStore';
import { Provider, useDispatch } from 'react-redux';
import { getGames, getUserBalance, loginUser } from './redux/ActionCreators';
import { useEffect } from 'react';
import Signin from './component/admin/login';
import AdminPanel from './component/admin/adminPanel';
import { useLocation } from 'react-router-dom';
import { AllGames } from './pages/games/games';
import { Bets } from './pages/bets/bets';

const store = createStore();

export const showToast = (msg) => (
  toast(msg, {
    position: toast.POSITION.BOTTOM_RIGHT,
    theme: 'dark'
  })
)

const Wrapper = ({children}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let loc = useLocation();
  useEffect(() => {
    window.scrollTo(0,0);
  }, [loc]);

  useEffect(() => {
    dispatch(getGames());
		if (localStorage.getItem('token')){
			fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/validate`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('token')}`
				}
			}).then(r => r.ok ? r.json() : ({success: false}))
			.then(res => {
				if (res.success){
					res.user.token = localStorage.getItem('token')
					dispatch(loginUser(res.user))
          dispatch(getUserBalance(res.user));
				}
				else{
					localStorage.removeItem('token');
					navigate('/');
				}
			})
		}
		else{
			navigate('/');
		}
	}, []);

  return(
    <>{children}</>
  );
}

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Wrapper><Navbar/><Home /></Wrapper>,
    },
    {
      path: "/game/:id",
      element: <Wrapper><Navbar/><TeamDetail /></Wrapper>,
    },
    {
      path: "/all-games",
      element: <Wrapper><Navbar/><AllGames /></Wrapper>,
    },
    {
      path: "/bets",
      element: <Wrapper><Navbar/><Bets /></Wrapper>,
    },
    {
      path: "/admin-login",
      element: <><Navbar/><Signin /></>
    },
    {
      path: "/admin-panel",
      element: <><Navbar/><AdminPanel /></>
    }
  ]);

  return (
    <Provider store={store}>
      <div className="App">
        <RouterProvider router={router} /> 
        <Footer />
        <ToastContainer />
      </div>
    </Provider>
  );
}

export default App;
