import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { loginAdmin, logoutAdmin } from '../../redux/ActionCreators';

export const AdminNavbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authedAdmin = useSelector(s => s.authedAdmin);

  const logout = async () => {
    try {
      dispatch(logoutAdmin({}));
      localStorage.clear();
      navigate("/admin-login");
    } catch {
      alert.show("Error while logging out");
    }
  };

  useEffect(() => {
    console.log(authedAdmin.authedAdmin)
    if (Object.keys(authedAdmin.authedAdmin).length === 0){
      navigate("/admin-login");
    }
  }, []);

  return (
    <>
    <nav className="navbar navbar-expand-lg bb-gray navbar-dark">
        <div className="container-fluid">
        <a className="navbar-brand" href="#">
        {/* <img className="logo" src={logo} alt="" /> */}
        {/* Football Games */}
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
                {/* <li className="nav-item active">
                    <NavLink className="nav-link" to="/create-bet" >Home</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/all-counts" >All Counts</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/user-points" >User Points</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/user-joined" >User Joined</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/wallet-generated" >Wallet Generated</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/played-game" >Played Game</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/most-played-game" >Most Played Game</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/jackpot-balances" >Jackpot Balances</NavLink>
                </li> */}
            </ul>
        </div>
        {/* {connectedAddress? */}
        <button className="themeBtn" onClick={logout}>Logout</button>
        {/* :
        <div className="themeBtn" onClick={connectWallet}>Connect Wallet</div>
        } */}
        </div>
    </nav>

    </>
  )
}
