import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../App";
import { getUserBalance } from "../../../redux/ActionCreators";

export default function PoolRow({p, game, getPools}){
  const [poolEntries, setPoolEntries] = useState([]);
  const authedUser = useSelector(s => s.authedUser);
  const dispatch = useDispatch();
  const getPoolEntries = () => {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/games/get-pool-entries/${p._id}`)
    .then(r => r.json())
    .then(r => {
      if (r.success){
        setPoolEntries(r.poolEntries)
      }
    })
  }

  const enterPool = () => {
    if (Object.keys(authedUser.authedUser).length === 0){
      return showToast('Login');
    }
    if (game?.ended){
      return showToast('Game has been ended');
    }
    if ((new Date(game?.endTime)) < (new Date())){
      return showToast('Game end time has been passed');
    }
    let result = document.getElementById(`enter-pool-full-${p._id}`).value;
    console.log(result);
    if (!result){
      return showToast('Select the team you want to bet on.');
    }
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/games/enter-pool`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: game._id, p_id: p._id, result })
    }).then(r => r.json())
    .then(r => {
      if (r.success){
        dispatch(getUserBalance(authedUser.authedUser));
        getPoolEntries();
        getPools();
      }
      else{
        showToast(r.msg);
      }
    });
  }

  const showOption = (opt) => {
    if (p.type === 'rumble'){
      return true
    }
    else{
      if (p.baseTeam){
        console.log(p.baseTeam, poolEntries);
        if (opt === 'draw'){
          if (!!poolEntries.find(pe => !pe.result)){
            return false;
          }
        }
        if (!!poolEntries.find(pe => pe.result?._id === opt)){
          return false;
        }
        else if (p.baseTeam?._id===opt){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        console.log(poolEntries.find(pe => !pe.result));
        if (opt === 'draw'){
          return false;
        }
        else if (!!poolEntries.find(pe => pe.result?._id === opt)){
          return false;
        }
        // else if (!!poolEntries.find(pe => !pe.result_)){
        //   return false;
        // }
        else{
          return true;
        }
      }
    }
  }

  useEffect(() => {
    getPoolEntries();
  }, []);
  
  return(
    <tr>
      <td scope='row'>
          <div className="inner">
              <div className="textStyle">{p.baseTeam ? p.baseTeam.name : 'draw'}</div>
          </div>
      </td>
      <td scope='row'>
          <div className="inner">
              <div className="textStyle">
                  <select id={`enter-pool-full-${p._id}`}>
                      <option value={''}>Select Option</option>
                      {(showOption('draw')) ? <option value='draw'>Draw</option> : <></>}
                      {(showOption(game.teamA._id)) ? <option value={game.teamA._id}>{game.teamA.name}</option> : <></>}
                      {(showOption(game.teamB._id)) ? <option value={game.teamB._id}>{game.teamB.name}</option> : <></>}
                  </select>
              </div>
          </div>
      </td>
      <td>
          <div className="inner">
              <div className="textStyle">{p.points}</div>
          </div>
      </td>
      <td>
          <div className="inner">
              <div className="textStyle">{poolEntries.length}</div>
          </div>
      </td>
      <td>
          <div className="inner text-center">
          {(!poolEntries.find(pe => pe.owner._id === authedUser?.authedUser?._id)) ? (
            p.type === 'pvp' ? (
              poolEntries.length < 3 ? (
                <button className='themeBtn1' onClick={enterPool}>Enter</button>
              ) : 
              <button className='themeBtn1'>Full</button>
            ) : (
              <button className='themeBtn1' onClick={enterPool}>Enter</button>
            )
          ) : (
            <>
              {p.type === 'pvp' ? (
                poolEntries.length === 3 ? (
                  <button className='themeBtn1' onClick={enterPool}>Full</button>
                ) : 
                <button className='themeBtn1'>Entered</button>
              ) : (
                <button className='themeBtn1'>Entered</button>
              )}
            </>
          )}
          </div>
      </td>
    </tr>
  );
}