import React, { useEffect, useState } from "react";
import bannerLogo1 from "../assets/images/bannerLogo1.png"
import mainHeading from "../assets/images/mainHeading.png"
import logoWhite from "../assets/images/logoWhite.svg"
import { months } from "../utils/constants";
import { useNavigate } from "react-router-dom";

export default function HotGame(){
  const [hotGame, setHotGame] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/hot-game`)
    .then(r => r.json())
    .then(r => {
      if (r.success){
        setHotGame(r.game);
      }
    })
  }, []);

  return(
    <div className="bannerMain">
      <div className="container">
          <div className="banner">

              <div className="r1">
                  <div className="img1">
                      <img src={bannerLogo1} alt="logo" />
                  </div>
                  <div className="img2">
                      <img src={logoWhite} alt="logo" />
                  </div>
              </div>
              <div className="r2">
                  <div className="col1">
                      <div className="imgDiv"><img src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/${hotGame?.teamA?.img}`} alt="" /></div>
                      <div className="title">{hotGame?.teamA?.name}</div>
                  </div>
                  <div className="content">
                      <div className="are">Are you betting on</div>
                      <h1>
                      <img src={mainHeading} alt="" />
                      </h1>
                      <h5 className='date'>
                        {new Date(hotGame?.startTime).getDate()} {months[new Date(hotGame?.startTime).getMonth()]} {new Date(hotGame?.startTime).getFullYear()}
                      </h5>
                      <div className="time">{new Date(hotGame?.startTime).getHours().toString().padStart(2, '0')}:{new Date(hotGame?.startTime).getMinutes().toString().padStart(2, '0')}</div>
                      <button className="themeBtn" onClick={() => navigate(`/game/${hotGame?._id}`)}>Bet Now</button>
                  </div>
                  <div className="col1">
                    <div className="imgDiv"><img src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/${hotGame?.teamB?.img}`} alt="" /></div>
                    <div className="title">{hotGame?.teamB?.name}</div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}