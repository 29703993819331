import React, { useState } from "react";
import BigNumber from 'bignumber.js';
import { showToast } from "../../App";
import Slider from '@appigram/react-rangeslider'
import { useDispatch, useSelector } from "react-redux";
import { getUserBalance } from "../../redux/ActionCreators";
import BuyPointModal from "./buyPointModal";

export default function BuyPointsSection({getEthBalance, sdBalance, chain}){
  const [pointsValue, setPointsValue] = useState(0);
  const [showBuyModal, setShowBuyModal] = useState(false);

  const handleChangePoint = (value1) => {
    setPointsValue(value1)
  }

  return(

    <>
    <div className="heading my-2">Buy <span>Football Points</span></div>
    <div className="sliderMain">
      <div>
      <Slider
        min={1}
        max={10000}
        step={1}
        value={pointsValue}
        onChange={(e)=>handleChangePoint(e)}
        />
      </div>
      <div className="r1">
        <div className="col1">
          <div onClick={() => setPointsValue(2000)} className={`fil ${pointsValue === 2000 ? 'active' : ''}`}>2k</div>
          <div onClick={() => setPointsValue(5000)} className={`fil ${pointsValue === 5000 ? 'active' : ''}`}>5k</div>
          <div onClick={() => setPointsValue(7000)} className={`fil ${pointsValue === 7000 ? 'active' : ''}`}>7k</div>
          <div onClick={() => setPointsValue(10000)} className={`fil ${pointsValue === 10000 ? 'active' : ''}`}>10k</div>
        </div>
        <div className="col2">
          <div className="pointsVal"><span>{pointsValue}</span> Football Points / <span>{(new BigNumber(process.env.REACT_APP_POINT_VAL)).dividedBy(new BigNumber(10**18)).multipliedBy(pointsValue).toString()}</span> ETH</div>
        </div>
      </div>
      <div className="r2">
        <div className="balance">Balance: <span>{sdBalance.substring(0,7)} ETH</span></div>
        <div className="themeBtn" style={{cursor: 'pointer'}} 
        onClick={() => (pointsValue <= 0 ? showToast('Points value should be greater than zero') : setShowBuyModal(true))}
        >{'Buy Points'}</div>
      </div>
    </div>
    <BuyPointModal setPointsValue={setPointsValue} getEthBalance={getEthBalance} show={showBuyModal} handleBuyClose={() => setShowBuyModal(false)} chain={chain} pointsValue={pointsValue} sdBalance={sdBalance} />
    </>
    // <div>
    //   <h4>Buy Honey  Football Points</h4>
    //   <div>
    //     <input type="number" onChange={(e) => setPointsValue(e.target.value)} placeholder="Points to buy" />
    //     <button className="theme-btn" onClick={buyPoints}>Buy Points</button>
    //     {loading ? <div>Buying.....</div> : <></>}
    //   </div>
    // </div>
  );
}