export const LOGGED_IN = 'LOGGED_IN';
export const UPDATE_USER = 'UPDATE_USER';
export const LOGGED_OUT = 'LOGGED_OUT';
export const PAIR_DATA = "PAIR_DATA";
export const LOGGED_IN_ADMIN = 'LOGGED_IN_ADMIN';
export const LOGGED_OUT_ADMIN = 'LOGGED_OUT_ADMIN';
export const SET_SERVER_MESSAGES = 'SET_SERVER_MESSAGES';
export const ADD_LIVE_MESSAGE = 'ADD_LIVE_MESSAGE';
export const SET_CHAT_NOTIFICATIONS = 'SET_CHAT_NOTIFICATIONS';
export const SET_CHAT_MESSAGE_FOCUS = 'SET_CHAT_MESSAGE_FOCUS';

export const SET_GAMES = 'SET_GAMES';
export const UPDATE_GAME = 'UPDATE_GAME';
export const REMOVE_GAMES = 'REMOVE_GAMES';