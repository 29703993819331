import React from 'react'
import footerICo from "../assets/images/footerICo.png"
export const Footer = () => {
  return (
    
    <footer>
        <div className="container">
        <div className="footerInner">
            <div className="icon">
            <img src={footerICo} alt="" />
            </div>
           
            <a href="mailto:info@capitan-copa.io" className='themeBtn1'>SEND EMAIL</a>
        </div>   
        </div>
    </footer>
  )
}
