import axios from "./axios.js";

const api = {};

api.adminLogin = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/login`;
  return axios.post(url, body);
};

// api.getUserData = function (body) {
//   const url = `${process.env.REACT_APP_SERVER_BASE_URL}/auth/get/admin/data`;
//   return axios.post(url, body);
// };
api.createBet = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/create-bet`;
  return axios.post(url, body);
};
api.getBetAdmin = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/get/bet`;
  return axios.get(url);
};
api.betNow = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/betnow`;
  return axios.post(url, body);
};
api.secondBetNow = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/second-betnow`;
  return axios.post(url, body);
};
api.getBetInstanceLive = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/get/betinstance/live`;
  return axios.get(url);
};
api.getBetInstanceCompleted = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/get/betinstance/completed`;
  return axios.get(url);
};
api.setWinner = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/set-winner`;
  return axios.post(url, body);
};
api.amountClaimed = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/claim-amount`;
  return axios.post(url, body);
};
api.amountWithdrawn = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/withdraw-amount`;
  return axios.post(url, body);
};
api.getBetInstanceFromId = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/get/instance-from-id?id=${body}`;
  return axios.get(url);
};
api.getBetInstanceFromIdObject = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/get/instance-from-id-Object?id=${body}`;
  return axios.get(url);
};
api.getBetInstanceFromUserId = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/get/instance-from-userId?id=${body}`;
  return axios.get(url);
};
api.getBetFromId = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/get/bet-from-id?id=${body}`;
  return axios.get(url);
};
api.getBetFromAmount = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/get/bet-from-amount?amount=${body}`;
  return axios.get(url);
};
api.getSpot = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/get/spot?id=${body}`;
  return axios.get(url);
};
api.updateSpot = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/update/spot`;
  return axios.post(url, body);
};
api.publicActivity = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/users/get-public-activity`;
  return axios.get(url);
};

api.uploadProfile = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/users/save/image/profile`;
  console.log(body);
  return axios.post(url, body);
};
api.editUser = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/users/edit`;
  console.log(body);
  return axios.post(url, body);
};




export default api;