import React, { useEffect, useState } from 'react'
import argentinaLogo from "../../assets/images/argentinaLogo.svg"
import brazilLogo from "../../assets/images/brazilLogo.svg"
import bannerLogo1 from "../../assets/images/bannerLogo1.png"
import logoWhite from "../../assets/images/logoWhite.svg"
import './games.css'
import { BettCard, BettCard2 } from '../../component/batCard'
import argentinaFlag from "../../assets/images/argentinaFlag.png"
import canadaFlag from "../../assets/images/canadaFlag.png"
import peruFlag from "../../assets/images/peruFlag.png"
import venezuelaFlag from "../../assets/images/venezuelaFlag.png"
import chileFlag from "../../assets/images/chileFlag.png"
import maxicoFlag from "../../assets/images/maxicoFlag.png"
import jamaicaFlag from "../../assets/images/jamaicaFlag.png"
import mainHeading from "../../assets/images/mainHeading.png"
import { useSelector } from 'react-redux'
import HotGame from '../../component/hotGame'

export const AllGames = () => {
  let [games, setGames] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/games/all`)
    .then(r => r.ok ? r.json() : {success: false})
    .then(r => {
      if (r.success){
        setGames(r.games.sort((a, b) => new Date(a.endTime) - new Date(b.endTime)));
      }
    });
  }, []);
  return (
    <div className='homePage'>
        {/* <HotGame /> */}
    <div className="container">
      <section className='betSection'>
        <div className="pageHeading">All Games</div>
        <div className="row">
          {games.map(g => (
            <div className="col-lg-4" key={`${g._id}-game`}>
              <BettCard2 game={g} flag1={canadaFlag} country1={"Canada"} flag2={argentinaFlag} country2={"Argentina"} />
            </div>
          ))}
        </div>
      </section>
    </div>
    </div>
  )
}
