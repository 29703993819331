import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { showToast } from '../../App';
import { months } from '../../utils/constants';


export default function HotGameModal({setModalShow, modalShow, games, setGames}){
  const setHotGame = () => {
    let game_id = document.getElementById('hot-game-option').value;
    if (!game_id){
      return showToast('Select game');
    }
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/set-hot-game`, {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('admin'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ game_id: game_id })
    }).then(r => r.ok ? r.json() : {success: false, msg: 'Failed'})
    .then(r => {
      if (r.success){
        showToast('Success');
        setGames((games) => games.map(g => g._id === r.game._id ? r.game : g));
      }
      else{
        showToast(r.msg);
      }
    });
  }
  return (
    <Modal
    show={modalShow}
    onHide={() => setModalShow(false)} 
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
   >
    <Modal.Body>
      <div className="betModal">
        <div className="closeIcon" onClick={() => setModalShow(false)}>
          <i className="fas fa-times"></i>
        </div>
        <form className="m-signin-form" onSubmit={(e) => e.preventDefault() }>
          <h2 className="text-center">Set Hot Game  </h2>
            <div className="form-group">
              <label>All Games:</label><br/>
              <select style={{width: '100%'}} className="mt-2" id="hot-game-option">
                {games.map(game => (
                  <option value={game._id}>{game.teamA.name} vs {game.teamB.name} {game.hot_game ? ' - Hot' : ''} (Created: {new Date(game.createdAt).getDate()}/{months[new Date(game.createdAt).getMonth()]})</option>
                ))}
              </select>
            </div>
            <div className="text-center m-signin-submit mt-3">
              <button onClick={setHotGame} className="btn">
                Set Hot Game
              </button>
            </div>
        </form>
      </div>
    </Modal.Body>
  </Modal>
  )
}