import React, { useState, useEffect } from 'react'
import logo from "../assets/images/logo.png";
import SignupBtn from './signModal';
import DepositModal from './depositModal/depositModel';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../redux/ActionCreators';
import { Link } from 'react-router-dom';

export const Navbar = () => {
  const [showDepositModal, setShowDepositModal] = useState(false);
  const authedUser = useSelector(s => s.authedUser);
  const dispatch = useDispatch();
  const logout = () => {
    localStorage.clear();
    dispatch(logoutUser({}));
  }
  return (
    <>
        <nav className="navbar navbar-expand-lg ">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img src={logo} alt="" className="logo" />
                </a>
                <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                >
                <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to="/">
                            Home
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/all-games">
                            All Games
                        </Link>
                    </li>
                    {Object.keys(authedUser.authedUser).length > 0 ? (
                        <li className="nav-item">
                            <Link className="nav-link" to="/bets">
                                YOUR BETS
                            </Link>
                        </li>
                    ) : (<></>)}
                </ul>
            {Object.keys(authedUser.authedUser).length > 0 ? (
                <>
                    <button className="themeBtn1 mx-2">
                        {authedUser?.authedUser?.username} | {authedUser?.authedUser?.points} Pts
                    </button>
                    <button onClick={logout} className="themeBtn1">
                        Logout
                    </button>
                    <button className="themeBtn1 ms-2" onClick={() => setShowDepositModal(true)} >
                        Deposit
                    </button>
                </>
            ) : (
                <SignupBtn />
            )}
            </div>
            </div>
        </nav>
        <DepositModal show={showDepositModal} setShow={setShowDepositModal} />
    </>
  )
}
