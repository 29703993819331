import React, { useEffect, useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import api from "../api";
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';

import axios from "axios";
import TeamSection from "./teamsection";
// import GameSection from "./gamesection";
// import TournamentSection from "./tournamentSection";
import { UserPointsPage } from "./UserPointsPage";
import { AdminNavbar } from "./adminNavbar";
import { showToast } from "../../App";
import GameSection from "./gamesection";
import PoolSection from "./poolSection";


 
export default function AdminPanel() {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const authedAdmin = useSelector((state) => state.authedAdmin);
  const [modalShow, setModalShow] = useState(false);
  const [betData, setBetData] = useState([]);
  const [img, setImg] = useState("");
  const [adminPoint, setAdminPoint] = useState(0);
  const [teams, setTeams] = useState([]);
  const [games, setGames] = useState([]);
  const [pools, setPools] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    // getAllBet();
    getTeams();
    getGames();
    getAllPool();
  }, [])

  const getAllPool = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/get-pools`, {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('admin')
      }
    }).then(r => r.ok ? r.json() : {pools: []});
    setPools(response.teams)
  }
  
  const getTeams = async() => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/teams`, {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('admin')
      }
    }).then(r => r.ok ? r.json() : {teams: []});
    setTeams(response.teams)
  }

  const getGames = async() => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/games`, {
      method: 'GET',
      headers:{
        'Authorization': localStorage.getItem('admin')
      }
    }).then(r => r.ok ? r.json() : {games: []});
    console.log("--------->",response)
    setGames(response.games)
  }

  const noExponents = (num) => {
    var data = String(num).split(/[eE]/);
    if (data.length == 1) return data[0];

    var z = '',
      sign = this < 0 ? '-' : '',
      str = data[0].replace('.', ''),
      mag = Number(data[1]) + 1;

    if (mag < 0) {
      z = sign + '0.';
      while (mag++) z += '0';
      return z + str.replace(/^\-/, '');
    }
    mag -= str.length;
    while (mag--) z += '0';
    return str + z;
  }

  const validation = () => {

    if (!data.amount) {
      return "Amount is required";
    }
    if (!data.noOfCards) {
      return "No of card is required";
    }
    return false;
  };

  const onInputChange = async (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const getPoints = async()=> {
    if(authedAdmin?.authedAdmin?.user){
      const url = `${process.env.REACT_APP_SERVER_BASE_URL}/users/get/points-userId`;
      let response = await axios.get(url,
          {headers: {
              Authorization: `${localStorage.getItem("admin")}`,
              'Content-Type': `application/json`
          }}
      );
      console.log(response);
      const point = response?.data?.points.reduce((accumulator, object) => {
        return accumulator + object.points;
      }, 0);
      console.log(point);
      setAdminPoint(point)
    }
  }

  useEffect(() => {
    getPoints()
  }, [authedAdmin?.authedAdmin?.user])
  
  return (
    <>
      <AdminNavbar />
      <section className="createBetPage">
        {/* <Header2 /> */}
        <div className="container-fluid">
          {/* {authedAdmin?.authedAdmin?.user?.wallet ? */}
          <>
          {page == 1 &&
          <div>
            <div>
              <div className="createBetBtn">
                  <h3>Admin Points: {adminPoint}</h3>
              </div>
  
              <div className="table-responsive mt-4 homeTable">
                <table className="table custom-table">
                  <thead>
                    <tr>
                      <th scope="col">Card Image</th>
                      <th scope="col">Bet Type</th>
                      <th scope="col">Amount</th>
                      <th scope="col">No. of cards</th>
                    </tr>
                  </thead>
                  <tbody>
                      {betData.map((item,key)=>(
                      <TableRow item={item} />
                      ))}
                  
                  </tbody>
                </table>
              </div>
            </div>
              <div>
                <TeamSection teams={teams} setTeams={setTeams} />
                <GameSection games={games} setGames={setGames} teams={teams} setTeams={setTeams} />
                {/* <PoolSection games={games} setGames={setGames} teams={teams} setTeams={setTeams} /> */}
                {/* <TournamentSection /> */}
              </div>
          </div>
          }
          {page == 2 && 
          <>
            <UserPointsPage />
          </>
          }
          </>
            {/* :
            <h1 className="text-center">Please Connect Wallet</h1>
            } */}
          
        </div>
        {/* <Footer /> */}
      </section>
      {/* <Loader loader={loader} /> */}
    </>
  )
};

const TableRow =({item}) => {
  return(
    <tr>
        <th>
          <div className="cardImg">
            <img src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/${item?.cardImg}`} alt="image" />
          </div>
        </th>
        <td>{item?.betType}</td>
        <td>{item?.amount}</td>
        <td>{item?.noOfCards}</td>
    </tr>
  )
}


