import React, { useEffect, useState } from 'react'
import argentinaLogo from "../../assets/images/argentinaLogo.svg"
import brazilLogo from "../../assets/images/brazilLogo.svg"
import bannerLogo1 from "../../assets/images/bannerLogo1.png"
import logoWhite from "../../assets/images/logoWhite.svg"
import { BettCard, BettCard2 } from '../../component/batCard'
import argentinaFlag from "../../assets/images/argentinaFlag.png"
import peruFlag from "../../assets/images/peruFlag.png"
import venezuelaFlag from "../../assets/images/venezuelaFlag.png"
import chileFlag from "../../assets/images/chileFlag.png"
import maxicoFlag from "../../assets/images/maxicoFlag.png"
import jamaicaFlag from "../../assets/images/jamaicaFlag.png"
import mainHeading from "../../assets/images/mainHeading.png"
import bvb from "../../assets/images/bvb.png"
import realMadrid from "../../assets/images/realMadrid.png"
import arrowDown from "../../assets/images/arrowDown.svg";
import arrowUp from "../../assets/images/arrowUp.svg";
import { useSelector } from 'react-redux';
import './detail.css'
import { CreatePoolModalFull } from '../../component/placePoolModal/fulltime/createPoolModalFull'
import { CreatePoolModalCorners } from '../../component/placePoolModal/corners/createPoolModalCorners'
import { CreatePoolModalGoals } from '../../component/placePoolModal/goals/createPoolModalGoals'
import { PoolAvailableFullModal, PoolAvailableModal } from '../../component/placePoolModal/fulltime/poolAvailableFullModal'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { PoolAvailableGoalModal } from '../../component/placePoolModal/goals/poolAvailableGoalModal'
import { PoolAvailableCornerModal } from '../../component/placePoolModal/corners/poolAvailableCornerModal'
import HotGame from '../../component/hotGame'
import { CreatePoolModalCards } from '../../component/placePoolModal/cards/createPoolModalCards'
import { PoolAvailableCardModal } from '../../component/placePoolModal/cards/poolAvailableCardModal'
import { months } from '../../utils/constants';


export const TeamDetail = () => {
    const { id } = useParams();
    const loc = useLocation();
    const navigate = useNavigate();
    const [side,setSide] = useState(['rumble', 'pvp'].includes(loc?.state?.type) ? loc?.state?.type : 'rumble');
    const [showCreateFull,setShowCreateFull]=useState(false);
    const [showCreateGoals,setShowCreateGoals]=useState(false);
    const [showCreateCorners,setShowCreateCorners]=useState(false);
    const [showCreateCards,setShowCreateCards]=useState(false);
    const [showAvailableFull,setShowAvailableFull]=useState(false);
    const [showAvailableGoal,setShowAvailableGoal]=useState(false);
    const [showAvailableCorner,setShowAvailableCorner]=useState(false);
    const [showAvailableCard,setShowAvailableCard]=useState(false);
    const games = useSelector(s => s.games);
    const [game, setGame] = useState({});
    const authedUser = useSelector(s => s.authedUser);
    const [pools, setPools] = useState([]);
    const [poolEntries, setPoolEntries] = useState([]);

    const getPools = () => {
        fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/games/get-pools/${id}`)
        .then(r => r.ok ? r.json() : {success: false})
        .then(res => {
            if (res.success){
                setPools(res.pools);
                setPoolEntries(res.poolEntries);
            }
            else{
                navigate('/');
            }
        })
    }

    useEffect(() => {
        if (id){
            fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/games/get-game/${id}`)
            .then(r => r.ok ? r.json() : {success: false})
            .then(res => {
                if (res.success){
                    console.log(res);
                    setGame(res.game);
                    getPools();
                }
                else{
                    navigate('/');
                }
            })
        }
    }, [id]);

    return (
        <div className='homePage detailPage'>
            {/* <div className="bannerMain">
                <div className="container">
                    <div className="banner">

                        <div className="r1">
                            <div className="img1">
                                <img src={bannerLogo1} alt="logo" />
                            </div>
                            <div className="img2">
                                <img src={logoWhite} alt="logo" />
                            </div>
                        </div>
                        <div className="r2">
                            <div className="col1">
                                <div className="imgDiv"><img src={argentinaLogo} alt="" /></div>
                                <div className="title">Argentina</div>
                            </div>
                            <div className="content">
                                <div className="are">Are you betting on</div>
                                <h1>
                                <img src={mainHeading} alt="" />
                                </h1>
                                <h5 className='date'>14 July 2024</h5>
                                <div className="time">21:00 PM</div>
                                <button className="themeBtn">Bet Now</button>
                            </div>
                            <div className="col1">
                                <div className="imgDiv"><img src={brazilLogo} alt="" /></div>
                                <div className="title">Brazil</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <HotGame />
            <div className="container">
                <section className='teamDetail'>
                    <div className="navM">
                        <button className={side==='pvp' ? 'active' : ''} onClick={()=>setSide('pvp')} >Heads up</button>
                        <button className={side==='rumble'? 'active' : ''} onClick={()=>setSide('rumble')} >Rumble</button>
                    </div>
                    <div className="teamRow">
                        <div className="team">
                            <div className="imgDiv"><img src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/${game?.teamA?.img}`} alt="" /></div>
                            <div className="title">{game?.teamA?.name}</div>
                        </div>
                        <div>
                            <div className="score">{game?.teamAGoals}-{game?.teamBGoals}</div>
                            {/* <div className="time"> */}
                                {new Date(game?.startTime) > new Date() ? <div className="time" style={{color: 'grey'}}>{`${months[new Date(game?.startTime).getMonth()]} ${new Date(game?.startTime).getDate()}, ${new Date(game?.startTime).getHours().toString().padStart(2, '0')}:${new Date(game?.startTime).getMinutes().toString().padStart(2, '0')}`}</div> : ''}
                                {new Date(game?.endTime) < new Date() ? <div className="time" style={{color: 'red'}}>Ended</div> : ''}
                                {(new Date(game?.endTime) > new Date()) && (new Date(game?.startTime) < new Date()) ? <div className="time" style={{color: '#10FE19'}}>{Math.round((((new Date().getTime() - new Date(game?.startTime)) ) ) / 60000)}</div> : ''}
                            {/* </div> */}
                        </div>
                        <div className="team">
                            <div className="imgDiv"><img src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/${game?.teamB?.img}`} alt="" /></div>
                            <div className="title">{game?.teamB?.name}</div>
                        </div>
                    </div>

                    <div className="detailRow">
                        <div className="r1">
                            <div className="name">Full Time Results Live</div>
                            <div className="btnM">
                                <button className="themeBtn1 me-3" onClick={()=>setShowAvailableFull(true)}>pools available</button>
                                <button className="themeBtn1" onClick={()=>setShowCreateFull(true)}>Create pool</button>
                            </div>
                        </div>
                        <div className="outerCardRow">
                            <div className="cardRow">
                                <div className="col1">
                                    {/* <div className="title">{game?.teamA?.name}</div>
                                    <div className="score">{game?.teamAGoals}-{game?.teamBGoals}</div>
                                    <div className="title">{game?.teamB?.name}</div> */}
                                </div>
                                <div className="col2">
                                    <ScoreCard name={"Total Pools"} score={pools.filter(p => (p.category === 'game_result' && (p.type === side))).length} />
                                    <ScoreCard name={"Total Bets"} score={`${poolEntries.reduce((sum, curr) => {
                                        if (curr.pool.category === 'game_result' && (curr.pool.type === side)){
                                            return sum + parseFloat(curr.pool.points);
                                        }
                                        else{
                                            return sum;
                                        }
                                    }, 0)} Points`} />
                                    {/* <ScoreCard name={"Real Madrid"} /> */}
                                    {/* <div className='bg-none card1'></div> */}
                                </div>
                            </div>
                            {poolEntries.filter(pe => pe.owner === authedUser.authedUser?._id && pe.pool.type === side && pe.pool.category === 'game_result').length > 0 ? (
                            <section className='tableSec'>
                                <div className="themeYellow" style={{paddingLeft: '20px'}}> Your Entries</div>
                                <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                    <thead>
                                        <tr>
                                        <th scope="col" colSpan={1}><div className="themeYellow"> Bet On</div></th>
                                        <th scope="col" colSpan={1}><div className="themeYellow"> Entry Points</div></th>
                                        <th scope="col" colSpan={1}><div className="themeYellow"> Won</div></th>
                                        <th scope="col" colSpan={1}><div className="themeYellow text-right" style={{marginRight: '60px'}}> Status</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {poolEntries.filter(pe => pe.owner === authedUser.authedUser?._id && pe.pool.type === side && pe.pool.category === 'game_result').map((poolEntry => (
                                        <tr>
                                            <td scope="row"><div className="inner">
                                                <div className="textStyle">
                                                    {/* <img className='arrow' src={arrowUp} alt="" /> 6.5 */}
                                                    {poolEntry.result ? (
                                                        <>
                                                        {poolEntry?.result === game.teamA?._id ? game.teamA.name : ''}
                                                        {poolEntry?.result === game.teamB?._id ? game.teamB.name : ''}
                                                        </>
                                                    ):('Draw')}
                                                </div>
                                            </div></td>
                                            <td><div className="inner"><div className="textStyle">{poolEntry?.pool?.points} <span>Points</span></div></div></td>
                                            <td><div className="inner"><div className="textStyle">{poolEntry?.winningAmount ? poolEntry?.winningAmount : '-'} <span>Points</span></div></div></td>
                                            <td>
                                                <div className="inner text-right d-flex jc-fe">
                                                    {poolEntry?.output ? (
                                                        <button className="won">{poolEntry?.output}</button>
                                                    ): (
                                                        <button className="won">Not Ended Yet</button>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                        )))}
                                    </tbody>
                                    </table>
                                </div>
                            </section>
                            ) : (<></>)}
                        </div>
                    </div>
                    <div className="detailRow">
                        <div className="r1">
                            <div className="name">Total Goals Live</div>
                            <div className="btnM">
                                <button className="themeBtn1 me-3" onClick={() => setShowAvailableGoal(true)}>pools available</button>
                                <button className="themeBtn1" onClick={()=>setShowCreateGoals(true)}>Create pool</button>
                            </div>
                        </div>
                        <div className="outerCardRow">
                            <div className="cardRow">
                                <div className="col1">
                                    {/* <div className="title">Total Goals</div> */}
                                    <div className="score">{parseInt(game?.teamAGoals ? game?.teamAGoals : '0')+parseInt(game?.teamBGoals ? game?.teamBGoals : '0')}</div>
                                </div>
                                <div className="col2">
                                    <ScoreCard name={"Total Pools"} score={pools.filter(p => p.category === 'total_goals' && (p.type === side)).length} />
                                    <ScoreCard name={"Total Bets"} score={`${poolEntries.reduce((sum, curr) => {
                                        if (curr.pool.category === 'total_goals' && (curr.pool.type === side)){
                                            return sum + parseFloat(curr.pool.points);
                                        }
                                        else{
                                            return sum;
                                        }
                                    }, 0)} Points`} />
                                </div>
                            </div>
                            {poolEntries.filter(pe => pe.owner === authedUser.authedUser?._id && pe.pool.type === side && pe.pool.category === 'total_goals').length > 0 ? (
                            <section className='tableSec'>
                                <div className="themeYellow" style={{paddingLeft: '20px'}}> Your Entries</div>
                                <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                    <thead>
                                        <tr>
                                        <th scope="col" colSpan={1}><div className="themeYellow"> Bet On</div></th>
                                        <th scope="col" colSpan={1}><div className="themeYellow"> Entry Points</div></th>
                                        <th scope="col" colSpan={1}><div className="themeYellow"> Won</div></th>
                                        <th scope="col" colSpan={1}><div className="themeYellow text-right" style={{marginRight: '60px'}}> Status</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {poolEntries.filter(pe => pe.owner === authedUser.authedUser?._id && pe.pool.type === side && pe.pool.category === 'total_goals').map((poolEntry => (
                                        <tr>
                                            <td scope="row"><div className="inner">
                                                <div className="textStyle"><img className='arrow' src={poolEntry.result_other === 'over' ? arrowUp : arrowDown} alt="" /> {poolEntry?.pool?.total_goals}</div>
                                            </div></td>
                                            <td><div className="inner"><div className="textStyle">{poolEntry?.pool?.points} <span>Points</span></div></div></td>
                                            <td><div className="inner"><div className="textStyle">{poolEntry?.winningAmount ? poolEntry?.winningAmount : '-'} <span>Points</span></div></div></td>
                                            <td>
                                                <div className="inner text-right d-flex jc-fe">
                                                    {poolEntry?.output ? (
                                                        <button className="won">{poolEntry?.output}</button>
                                                    ): (
                                                        <button className="won">Not Ended Yet</button>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                        )))}
                                    </tbody>
                                    </table>
                                </div>
                            </section> ) : (<></>)}
                        </div>
                    </div>
                    <div className="detailRow">
                        <div className="r1">
                            <div className="name">Total Corners Live</div>
                            <div className="btnM">
                                <button className="themeBtn1 me-3"onClick={()=>setShowAvailableCorner(true)}>pools available</button>
                                <button className="themeBtn1" onClick={()=>setShowCreateCorners(true)}>Create pool</button>
                            </div>
                        </div>
                        <div className="outerCardRow">
                            <div className="cardRow">
                                <div className="col1">
                                    {/* <div className="title">Corner Score</div> */}
                                    <div className="score">{game?.corners ? game?.corners : '0'}</div>
                                </div>
                                <div className="col2">
                                    <ScoreCard name={"Total Pools"} score={pools.filter(p => p.category === 'total_corners' && (p.type === side)).length} />
                                    <ScoreCard name={"Total Bets"} score={`${poolEntries.reduce((sum, curr) => {
                                        if (curr.pool.category === 'total_corners' && (curr.pool.type === side)){
                                            return sum + parseFloat(curr.pool.points);
                                        }
                                        else{
                                            return sum;
                                        }
                                    }, 0)} Points`} />
                                    {/* <div className='bg-none card1'></div> */}
                                </div>
                            </div>
                            {poolEntries.filter(pe => pe.owner === authedUser.authedUser?._id && pe.pool.type === side && pe.pool.category === 'total_corners').length > 0 ? (
                            <section className='tableSec'>
                                <div className="themeYellow" style={{paddingLeft: '20px'}}> Your Entries</div>
                                <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                    <thead>
                                        <tr>
                                        <th scope="col" colSpan={1}><div className="themeYellow"> Bet On</div></th>
                                        <th scope="col" colSpan={1}><div className="themeYellow"> Entry Points</div></th>
                                        <th scope="col" colSpan={1}><div className="themeYellow"> Won</div></th>
                                        <th scope="col" colSpan={1}><div className="themeYellow text-right" style={{marginRight: '60px'}}> Status</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {poolEntries.filter(pe => pe.owner === authedUser.authedUser?._id && pe.pool.type === side && pe.pool.category === 'total_corners').map((poolEntry => (
                                        <tr>
                                            <td scope="row"><div className="inner">
                                                <div className="textStyle"><img className='arrow' src={poolEntry.result_other === 'over' ? arrowUp : arrowDown} alt="" /> {poolEntry?.pool?.total_corners}</div>
                                            </div></td>
                                            <td><div className="inner"><div className="textStyle">{poolEntry?.pool?.points} <span>Points</span></div></div></td>
                                            <td><div className="inner"><div className="textStyle">{poolEntry?.winningAmount ? poolEntry?.winningAmount : '-'} <span>Points</span></div></div></td>
                                            <td>
                                                <div className="inner text-right d-flex jc-fe">
                                                    {poolEntry?.output ? (
                                                        <button className="won">{poolEntry?.output}</button>
                                                    ): (
                                                        <button className="won">Not Ended Yet</button>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                        )))}
                                    </tbody>
                                    </table>
                                </div>
                            </section>
                            ) : (<></>)}
                        </div>
                    </div>
                    <div className="detailRow">
                        <div className="r1">
                            <div className="name">Total Cards Live</div>
                            <div className="btnM">
                                <button className="themeBtn1 me-3"onClick={()=>setShowAvailableCard(true)}>pools available</button>
                                <button className="themeBtn1" onClick={()=>setShowCreateCards(true)}>Create pool</button>
                            </div>
                        </div>
                        <div className="outerCardRow">
                            <div className="cardRow">
                                <div className="col1">
                                    {/* <div className="title">Corner Score</div> */}
                                    <div className="score">{game?.cards ? game?.cards : '0'}</div>
                                </div>
                                <div className="col2">
                                    <ScoreCard name={"Total Pools"} score={pools.filter(p => p.category === 'total_cards' && (p.type === side)).length} />
                                    <ScoreCard name={"Total Bets"} score={`${poolEntries.reduce((sum, curr) => {
                                        if (curr.pool.category === 'total_cards' && (curr.pool.type === side)){
                                            return sum + parseFloat(curr.pool.points);
                                        }
                                        else{
                                            return sum;
                                        }
                                    }, 0)} Points`} />
                                    {/* <div className='bg-none card1'></div> */}
                                </div>
                            </div>
                            {poolEntries.filter(pe => pe.owner === authedUser.authedUser?._id && pe.pool.type === side && pe.pool.category === 'total_cards').length > 0 ? (
                            <section className='tableSec'>
                                <div className="themeYellow" style={{paddingLeft: '20px'}}> Your Entries</div>
                                <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                    <thead>
                                        <tr>
                                        <th scope="col" colSpan={1}><div className="themeYellow"> Bet On</div></th>
                                        <th scope="col" colSpan={1}><div className="themeYellow"> Entry Points</div></th>
                                        <th scope="col" colSpan={1}><div className="themeYellow"> Won</div></th>
                                        <th scope="col" colSpan={1}><div className="themeYellow text-right" style={{marginRight: '60px'}}> Status</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {poolEntries.filter(pe => pe.owner === authedUser.authedUser?._id && pe.pool.type === side && pe.pool.category === 'total_cards').map((poolEntry => (
                                        <tr>
                                            <td scope="row"><div className="inner">
                                                <div className="textStyle"><img className='arrow' src={poolEntry.result_other === 'over' ? arrowUp : arrowDown} alt="" /> {poolEntry?.pool?.total_cards}</div>
                                            </div></td>
                                            <td><div className="inner"><div className="textStyle">{poolEntry?.pool?.points} <span>Points</span></div></div></td>
                                            <td><div className="inner"><div className="textStyle">{poolEntry?.winningAmount ? poolEntry?.winningAmount : '-'} <span>Points</span></div></div></td>
                                            <td>
                                                <div className="inner text-right d-flex jc-fe">
                                                    {poolEntry?.output ? (
                                                        <button className="won">{poolEntry?.output}</button>
                                                    ): (
                                                        <button className="won">Not Ended Yet</button>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                        )))}
                                    </tbody>
                                    </table>
                                </div>
                            </section>
                            ) : (<></>)}
                        </div>
                    </div>
                </section>
                <section className='betSection'>
                    <div className="pageHeading">Upcoming Games</div>
                    <div className="row">
                        {games.filter(g => new Date(g.startTime) > new Date()).map(game => (
                            <div className="col-lg-4" key={`${game._id}-detail-game-upcoming`}>
                                <BettCard2 upcoming={true} game={game}/>
                            </div>
                        ))}
                        {/* <div className="col-lg-4">
                        <BettCard2 flag1={argentinaFlag} country1={"Argentina"} flag2={venezuelaFlag} country2={"venezuela"} />
                        </div>
                        <div className="col-lg-4">
                        <BettCard2 flag1={maxicoFlag} country1={"Maxico"} flag2={jamaicaFlag} country2={"jamaica"} />
                        </div>
                        */}
                    </div>
                </section>
            </div>
            <CreatePoolModalFull getPools={getPools} type={side} pools={pools} setPools={setPools} game={game} show={showCreateFull} setShow={setShowCreateFull} />
            <CreatePoolModalGoals getPools={getPools} type={side} pools={pools} setPools={setPools} game={game} show={showCreateGoals} setShow={setShowCreateGoals} />
            <CreatePoolModalCorners getPools={getPools} type={side} pools={pools} setPools={setPools} game={game} show={showCreateCorners} setShow={setShowCreateCorners} />
            <CreatePoolModalCards getPools={getPools} type={side} pools={pools} setPools={setPools} game={game} show={showCreateCards} setShow={setShowCreateCards} />
            <PoolAvailableFullModal getPools={getPools} game={game} pools={pools.filter(p => ((p.category === 'game_result') && (p.type === side)))} show={showAvailableFull} setShow={setShowAvailableFull} />
            <PoolAvailableGoalModal getPools={getPools} game={game} pools={pools.filter(p => ((p.category === 'total_goals') && (p.type === side)))} show={showAvailableGoal} setShow={setShowAvailableGoal} />
            <PoolAvailableCornerModal getPools={getPools} game={game} pools={pools.filter(p => ((p.category === 'total_corners') && (p.type === side)))} show={showAvailableCorner} setShow={setShowAvailableCorner} />
            <PoolAvailableCardModal getPools={getPools} game={game} pools={pools.filter(p => ((p.category === 'total_cards') && (p.type === side)))} show={showAvailableCard} setShow={setShowAvailableCard} />
        </div>
    )
}

const ScoreCard = ({name,score}) => {
    return(
        <div className="card1">
            <div className="title">{name}</div>
            <div className="status">{score}</div>
        </div>
    )
}