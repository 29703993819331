import React from 'react'
import '../pool.css'
import { Modal } from 'react-bootstrap'
import { showToast } from '../../../App'
import { useDispatch, useSelector } from 'react-redux'
import { getUserBalance } from '../../../redux/ActionCreators'

export const CreatePoolModalFull = ({getPools, game, show, setShow, pools, setPools, type}) => {
    const authedUser = useSelector(s => s.authedUser);
    const dispatch = useDispatch();
    const createPool = () => {
        if (Object.keys(authedUser.authedUser).length === 0){
            return showToast('Sign in');
        }
        let result = document.getElementById('create-pool-type-full').value;
        let pts = document.getElementById('create-pool-pts-full').value;
        if (!result){
            showToast('Select Result');
        }
        if (!pts){
            showToast('Set points per entry');
        }
        fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/games/create-full-pool`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ teamId: result, points: pts, id: game._id, type })
        }).then(r => r.ok ? r.json() : {success:false, msg:'Failed'})
        .then(r => {
            console.log(r);
            if (r.success){
                setPools(pools.concat([r.pool]));
                // if (type === 'pvp'){
                    dispatch(getUserBalance(authedUser.authedUser));
                    getPools();
                // }
                showToast('Success');
            }
            else{
                showToast(r.msg);
            }
        })
    }

    return (
        <Modal
        show={show}
        onHide={() => setShow(false)} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='BModal'
        >
            <Modal.Body>
            <div className="betModal">
                <div className="closeIcon" onClick={() => setShow(false)}>
                <i className="fas fa-times"></i>
                </div>
                <form className="m-signin-form" onSubmit={(e) => e.preventDefault() }>
                    <h2 className="text-center mb-5">Create Pool (Result)</h2>
                    <div className="createPoolRow">
                        {/* <div className="form-group me-4">
                            {/* <input
                                className="text"
                                type="text"
                                name="teamName"
                                placeholder="Team Name"
                            /> */}
                            {/* <label>Points per Entry:</label><hr/>
                            <input type='number' id='create-pool-pts-full' style={{width: '100%'}} />
                        </div> */}
                        <div className="form-group me-4 mt-auto">
                            <select name="" id="create-pool-pts-full" className="text select">
                                <option value="">Select Points</option>
                                {game?.pointsOptions?.map(o => (
                                    <option value={o}>{o}</option>
                                ))}
                                {/* <option value="draw">Draw</option>
                                <option value={game?.teamA?._id}>{game?.teamA?.name}</option>
                                <option value={game?.teamB?._id}>{game?.teamB?.name}</option> */}
                            </select>
                        </div>

                        <div className="form-group mt-auto">
                            <select name="" id="create-pool-type-full" className="text select">
                                <option value="">Select Game Result</option>
                                <option value="draw">Draw</option>
                                <option value={game?.teamA?._id}>{game?.teamA?.name}</option>
                                <option value={game?.teamB?._id}>{game?.teamB?.name}</option>
                            </select>
                        </div>
                        
                    </div>
                
                    <div className="text-center continueBtn pb-4">
                        <button onClick={createPool} className="themeBtn1">
                            Continue
                        </button>
                    </div>
                </form>
            </div>
            </Modal.Body>
        </Modal>
    );
}
