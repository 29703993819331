import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import BuyPointsSection from './BuyPointSection';
import RedeemPointsSection from './RedeemPointsSection';
import WithdrawEthSection from './WithdrawEthSection';

import GenerateSDAddress from "./generateSDAddress";
import '@appigram/react-rangeslider/lib/index.css';
import EthIco from '../../assets/images/ethIco.svg'
import creditCardIco from '../../assets/images/creditCardIco.svg'
import bitcoinIco from '../../assets/images/bitcoinIco.svg'
import litecoinIco from '../../assets/images/litecoinIco.svg'
import tetherIco from '../../assets/images/tetherIco.svg'
import beeIco from '../../assets/images/beeIco.svg'
import externalIco from '../../assets/images/externalIco.svg'
import InternalWalletSection from "./internalWallet";
import Form from 'react-bootstrap/Form';
import './deposit.css'
import web3 from '../../web3';
import BigNumber from 'bignumber.js';
import { Web3 } from 'web3';
import { useSelector } from "react-redux";
import { showToast } from "../../App";

const depositOption = [
  {
    img:EthIco,
    name:'Ethereum',
  },
  {
    img:creditCardIco,
    name:'Credit Card',
    comingSoon: true
    // off:'40'
  },
  {
    img:bitcoinIco,
    name:'Bitcoin',
    comingSoon: true
    // off:'40'
  },
  {
    img:litecoinIco,
    name:'Litecoin',
    comingSoon: true
    // off:'40'
  },
  {
    img:tetherIco,
    name:'Tether(TRC20)',
    comingSoon: true
    // off:'40'
  },

];
const withdrawOption = [
  {
    img:creditCardIco,
    name:'Redeem Football Points',
  },
  {
    img:externalIco,
    name:'External Wallet',
  },
  {
    img:creditCardIco,
    name:'Internal Wallet',
  },
];

export default function DepositModal({show, setShow}){
  const [sdBalance, setSDBalance] = useState('0');

  const [selectedWallet,setSelectedWallet] = useState(0);
  const [selectedRedeem,setSelectedRedeem] = useState("Redeem Football Points");
  const [showDeposite, setShowDeposite] = useState(true);
  const [chain, setChain] = useState('ETH');
  const [isDisabled, setDisabled] = useState(false);

  const authedUser = useSelector(s => s.authedUser);

  useEffect(() => {
    if (chain === 'ZK'){
      web3.web3 = new Web3(process.env.REACT_APP_ZK_RPC_URL);
    }
    if (chain === 'ETH'){
      web3.web3 = new Web3(process.env.REACT_APP_ETH_RPC_URL);
    }
    if (Object.keys(authedUser.authedUser).length > 0){
      getEthBalance();
    }
  }, [chain]);

  const getEthBalance = async () => {
    if (Object.keys(authedUser.authedUser).length > 0){
      if (authedUser?.authedUser?.sdAddress){
        let b = await web3.web3.eth.getBalance(authedUser?.authedUser?.sdAddress);
        let blnc = (new BigNumber(b)).dividedBy(new BigNumber(10**18));
        setSDBalance(blnc.toString());
      }
    }
  }
  function copyText() {
    // Copy the text inside the text field
    navigator.clipboard.writeText(authedUser?.authedUser?.sdAddress);
    showToast('Copied');
  }

  useEffect(( ) => {
    getEthBalance();
  },[authedUser.authedUser]);

  return(
    <Modal className="pointsModal" dialogClassName="modal-90w" centered show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false}>
      <Modal.Body className="p-0">
        <div className="closeIco" onClick={() => setShow(false)}><i class="fa-solid fa-xmark"></i></div>
        <div className="row mx-0">
          <div className="col-md-5 p-0">
            <div className="walletCol">
              <div className="heading">
                <>Internal Wallet</>
                {/* <Form.Check // prettier-ignore
                  type="switch"
                  onChange={(e) => {setChain(chain === 'ETH' ? 'ZK' : 'ETH')}}
                  id="custom-switch"
                  label={chain === 'ETH' ? 'Base' : 'ZK'}
                /> */}
                <div className="uPoints">{sdBalance} ETH</div>
              </div>
              {authedUser?.authedUser?.sdAddress ? (
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <>{authedUser?.authedUser?.sdAddress.substring(0,6)}...{authedUser?.authedUser?.sdAddress.substring(authedUser?.authedUser?.sdAddress?.length-6,authedUser?.authedUser?.sdAddress?.length)}</>
                  <span onClick={copyText} style={{
                    backgroundColor: '#000',
                    cursor: 'pointer',
                    border: '1px solid #00226499',
                    borderRadius: '12px',
                    color: '#848484',
                    fontSize: '12px',
                    fontWeight: '500',
                    padding: '3px 10px'
                  }}>Copy</span>
                </div>
              ) : (<></>)}
              <div className="btnM">
                <button className={showDeposite ? "active":"" } onClick={()=>setShowDeposite(true)}>Deposit</button>
                <button className={showDeposite ? "":"active" } onClick={()=>setShowDeposite(false)}>Withdraw</button>
              </div>
              <div className="optionMain">
                {showDeposite?
                <>
                {depositOption.map((val,key)=>(
                <div className={selectedWallet == key?"option active":"option" } onClick={()=> setSelectedWallet(key)}  key={key}>
                  <div className="name">
                    <img src={val.img} alt="" className="ico" />
                    <div>{val.name}</div>
                  </div>
                  {val?.off &&
                  <span>{val?.off}% OFF</span>
                  }
                  {val?.comingSoon &&
                  <span>Coming Soon</span>
                  }
                </div>
                ))}
                </>
                :
                withdrawOption.map((val,key)=>(
                  <div className={selectedRedeem == val.name ?"option active":"option" } onClick={()=> setSelectedRedeem(val.name)}  key={key}>
                    <div className="name">
                      <img src={val.img} alt="" className="ico" />
                      <div>{val.name}</div>
                    </div>
                    {val?.off &&
                    <span>{val?.off}% OFF</span>
                    }
                  </div>
                  ))
                }
              </div>
              {/* <div className="historyBtn">
                <Form.Check // prettier-ignore
                  type="switch"
                  onChange={(e) => {setChain(chain === 'ETH' ? 'ZK' : 'ETH')}}
                  id="custom-switch"
                  label={chain === 'ETH' ? 'Base' : 'ZK'}
                />
              </div> */}
            </div>
          </div>
          <div className="col-md-7">
            <div className="mainColCol py-3">
              {authedUser.authedUser.sdAddress ? (
                <>
                {showDeposite ?
                <>
                  <BuyPointsSection chain={chain} 
                  setDisabled={setDisabled} getEthBalance={getEthBalance} sdBalance={sdBalance} />
                </>
                :
                <>
                {selectedRedeem == "Redeem Football Points" && 
                  <RedeemPointsSection getEthBalance={getEthBalance} setDisabled={setDisabled} chain={chain} />
                }
                {selectedRedeem == "External Wallet" && 
                  <WithdrawEthSection sdBalance={sdBalance} getEthBalance={getEthBalance} chain={chain} />          
                }
                 {selectedRedeem == "Internal Wallet" && 
                  <InternalWalletSection sdBalance={sdBalance} getEthBalance={getEthBalance} />          
                }
                </>
                }
                </>
              ) : (<>
                <GenerateSDAddress/>
              </>)}
            </div>
          </div>
        </div>
        {/* <button className="theme-btn" disabled={isDisabled} onClick={() => setShow(false)}>Close</button> */}
      </Modal.Body>
    </Modal>
  );
}
