import React, { useEffect, useState } from 'react'
import argentinaLogo from "../../assets/images/argentinaLogo.svg"
import brazilLogo from "../../assets/images/brazilLogo.svg"
import bannerLogo1 from "../../assets/images/bannerLogo1.png"
import logoWhite from "../../assets/images/logoWhite.svg"
import './home.css'
import { BettCard, BettCard2 } from '../../component/batCard'
import argentinaFlag from "../../assets/images/argentinaFlag.png"
import canadaFlag from "../../assets/images/canadaFlag.png"
import peruFlag from "../../assets/images/peruFlag.png"
import venezuelaFlag from "../../assets/images/venezuelaFlag.png"
import chileFlag from "../../assets/images/chileFlag.png"
import maxicoFlag from "../../assets/images/maxicoFlag.png"
import jamaicaFlag from "../../assets/images/jamaicaFlag.png"
import mainHeading from "../../assets/images/mainHeading.png"
import { useSelector } from 'react-redux'
import HotGame from '../../component/hotGame'

export const Home = () => {
  const games = useSelector(s => s.games);
  const [poolEntries, setPoolEntries] = useState([]);
  const authedUser = useSelector(s => s.authedUser);
  const getPoolEntries = () => {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/get-pool-entries`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(r => r.json())
    .then(r => {
      if (r.success){
        setPoolEntries(r.poolEntries)
      }
    })
  }
  useEffect(() => {
    if (Object.keys(authedUser?.authedUser).length > 0){
      getPoolEntries()
    }
    else{
      setPoolEntries([]);
    }
  },[authedUser?.authedUser])
  return (
    <div className='homePage'>
        <HotGame />
    <div className="container">
        <section className='betSection'>
            <div className="pageHeading">Your Active Pools</div>
            <div className="row">
              {poolEntries.filter(pe => (new Date(pe?.game?.endTime) > new Date())).map(poolEntry => (
                <div className="col-lg-4" key={poolEntry._id}>
                  <BettCard poolEntry={poolEntry} title={"Corner"} badge={"Heads up"}  />
                </div>
              ))}
                {/* <div className="col-lg-4">
                  <BettCard title={"Corner"} badge={"Heads up"}  />
                </div>
                <div className="col-lg-4">
                  <BettCard title={"Fulltime result"} badge={"Lobby"} />
                </div>
                <div className="col-lg-4">
                  <BettCard title={"Panelty"} badge={"Lobby"} />
                </div> */}
            </div>
        </section>
        <section className='betSection'>
            <div className="pageHeading">Live Games</div>
            <div className="row">
              {games.filter(g => ((new Date(g.startTime) < new Date()) && (new Date(g.endTime) > new Date()))).map(g => (
                <div className="col-lg-4" key={`${g._id}-game`}>
                  <BettCard2 game={g} flag1={canadaFlag} country1={"Canada"} flag2={argentinaFlag} country2={"Argentina"} />
                </div>
              ))}
            </div>
        </section>
        <section className='betSection'>
            <div className="pageHeading">Upcoming Games</div>
            <div className="row">
              {games.filter(g => new Date(g.startTime) > new Date()).map(g => (
                <div className="col-lg-4" key={`${g._id}-game-upcoming`}>
                  <BettCard2 upcoming={true} game={g} flag1={peruFlag} country1={"Peru"} flag2={chileFlag} country2={"chile"} />
                </div>
              ))}
            </div>
        </section>
    </div>
    </div>
  )
}
