import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../redux/ActionCreators";
import { showToast } from "../../App";

export default function GenerateSDAddress(){
  const authedUser = useSelector(s => s.authedUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  
  const getCustodialAddress = async () => {
    if (Object.keys(authedUser.authedUser).length > 0){
        try{
            setLoading(true);
            let res = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/get-sd-address`, {
                method: 'GET',
                headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
            }).then(r => r.ok ? r.json() : ({success: false, msg: 'Failed'}))
            if (res.success){
                dispatch(updateUser({...authedUser.authedUser, sdAddress: res.user.sdAddress}))
                showToast('Address generated');
            }
            else{
                showToast('Failed to get address');        
            }
            setLoading(false);
        }
        catch(e){
            console.log(e);
            setLoading(false);
        }
    }
    else{
        showToast("Login");
        navigate('/');
    }
  }

  return(
    <>
      <div className="heading my-2">Generate Address</div>
      <div className="sliderMain">
        <div className="inputRow">
          <div className="col1">
            <div className="sbtn mt-0 text-align-center" style={{textAlign: 'center'}}>
              <button className="themeBtn px-4" style={{fontSize: '20px'}} 
              onClick={loading ? () => {} : getCustodialAddress}
              >{loading ? 'Generating...' : 'Get Your Wallet Address'}</button>      
            </div>
          </div>
        </div>
      </div>
    </>
  );
}