import React, { useEffect, useState } from "react";
import BigNumber from 'bignumber.js';
import { Modal } from "react-bootstrap";
import { showToast } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { getUserBalance } from "../../redux/ActionCreators";

export default function BuyPointModal({show, handleBuyClose, pointsValue, setPointsValue, chain, sdBalance, getEthBalance}){
  const [txFee, setTxFee] = useState(0);
  const [loading, setLoading] = useState(false);
  const authedUser = useSelector(s => s.authedUser);
  const dispatch = useDispatch();

  const buyPoints = async() => {
    try {
        if(Object.keys(authedUser?.authedUser).length > 0){
            if (pointsValue <= 0){
              showToast('Points should be greater than 0');
              return;
            }
            let eth_val = new BigNumber(pointsValue * parseInt(process.env.REACT_APP_POINT_VAL)).dividedBy(new BigNumber(10**18));
            if (eth_val.plus(txFee).isGreaterThanOrEqualTo(sdBalance)){
              showToast('Insufficient Balance');
              return;
            }
            setLoading(true);
            // setDisabled(true);
            let res = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/purchase-points`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({points: pointsValue, chain: chain})
            }).then(r => r.ok ? r.json() : ({success: false, msg: 'Failed to buy points'}));
            if (res.success){
                dispatch(getUserBalance(authedUser.authedUser));
                getEthBalance();
                showToast('Success');
                setPointsValue(0);
                handleBuyClose();
            }
            else{
                showToast(res.msg);
            }
            setLoading(false)
          }
        else{
            alert("please connect wallet");
        }
    } catch (error) {
        console.log(error);
        setLoading(false)
        showToast("Something went wrong");
    }
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/get-est-gas-fees/purchase`)
    .then(r => r.json())
    .then(r => {
      if (r.success){
        setTxFee(r.txFees);
      }
      else{
        showToast('Failed to estimate gas fees. Please refresh the window.');
      }
    })
  }, []);

  return(
    <Modal id="WalletModal" className="WalletModal" show={show} onHide={handleBuyClose} centered >
				<Modal.Body>
					<div >
					<h1>Buy Points</h1>
					<div style={{color: 'black !important'}}>
						<div className="buyRecept mb-2">
							<label>Number of points</label>
                            <div>{pointsValue}</div>
						</div>
						<div className="buyRecept mb-2">
							<label>Amount </label>
              <div>{new BigNumber(pointsValue * parseInt(process.env.REACT_APP_POINT_VAL)).dividedBy(new BigNumber(10**18)).toString()} ETH</div>
						</div>
						<div className="buyRecept mb-2">
							<label>Tx Fee</label>
                            <div>{txFee ? `${txFee} ETH`: 'Calculating...'} </div>
						</div>
						<div className="buyRecept mb-2">
							<label>Total Amount </label>
                            <div>{txFee ? `${((new BigNumber(pointsValue * (parseInt(process.env.REACT_APP_POINT_VAL))).dividedBy(new BigNumber(10**18)).plus(txFee)))} ETH` : 'Calculating...'}</div>
						</div>
						<div className="buyRecept mb-2">
							<label>Your Balance </label>
                            <div>{sdBalance}</div>
						</div>
						<div className="btnDiv mt-4">
							<button className='me-2' onClick={handleBuyClose}>Cancel</button>
							<button onClick={loading ? () => {} : buyPoints}>{loading ? 'Buying..' : 'Buy'}</button>
						</div>
					</div>
					<div className="closeIcon" onClick={handleBuyClose}>
					<i class="fas fa-times"></i>
					</div>
					</div>
				</Modal.Body>
			</Modal>
  );
}