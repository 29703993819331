import React from 'react'
import argentinaLogo from "../assets/images/argentinaLogo.png"
import brazilLogo from "../assets/images/brazilLogo.png"
import arrowDown from "../assets/images/arrowDown.svg"
import arrowUp from "../assets/images/arrowUp.svg"
import cornerIco from "../assets/images/cornerIco.svg"
import argentinaFlag from "../assets/images/argentinaFlag.png"
import canadaFlag from "../assets/images/canadaFlag.png"
import { Link, useNavigate } from 'react-router-dom'
import { months } from '../utils/constants';


const getColor = (o) => {
    if (o === 'No Result'){
        return 'white';
    }
    if (o === 'Won'){
        return '#10FE19';
    }
    if (o === 'Lost'){
        return 'red';
    }
}

export const BettCard = ({title,badge, poolEntry}) => {

    const navigate = useNavigate();
  return (
    <div className="betCardMain ">
        <div className="betCard active">
            <div className="team">
                <div className="imgDiv">
                    <img src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/${poolEntry?.game?.teamA?.img}`} alt="" />
                </div>
                <div className="title">{poolEntry?.game?.teamA?.name}</div>
            </div>
            <div className="score">
                <div className="badge1">
                    {poolEntry?.pool?.type === 'pvp' ? 'HEADS UP' : ''}
                    {poolEntry?.pool?.type === 'rumble' ? 'RUMBLE' : ''}
                </div>
                <div className="count">{poolEntry?.game?.teamAGoals}-{poolEntry?.game?.teamBGoals}</div>
                <div className="time">
                    {new Date(poolEntry?.game?.startTime) > new Date() ? 
                    <div className="time" style={{color: 'grey'}}>
                    {`${months[new Date(poolEntry?.game?.startTime).getMonth()]} ${new Date(poolEntry?.game?.startTime).getDate()}, ${new Date(poolEntry?.game?.startTime).getHours().toString().padStart(2, '0')}:${new Date(poolEntry?.game?.startTime).getMinutes().toString().padStart(2, '0')}`}
                    </div>
                    : ''}
                    {(new Date(poolEntry?.game?.endTime) < new Date()) ? <div className="time" style={{color: 'red'}}>Ended</div> : ''}
                    {((new Date(poolEntry?.game?.endTime) > new Date()) && (new Date(poolEntry?.game?.startTime) < new Date())) ? <div className="time">{Math.round((((new Date().getTime() - new Date(poolEntry?.game?.startTime)) ) ) / 60000)}</div> : ''}

                    {/* {Math.round((((new Date().getTime() - new Date(poolEntry?.game?.startTime)) ) ) / 60000) > 90 ? '90' : Math.round((((new Date().getTime() - new Date(poolEntry?.game?.startTime)) ) ) / 60000)}’ */}
                </div>
            </div>
            <div className="team">
                <div className="imgDiv">
                    <img src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/${poolEntry?.game?.teamB?.img}`} alt="" />
                </div>
                <div className="title">{poolEntry?.game?.teamB?.name}</div>
            </div>
        </div>
        <div className="btnMain">
            <div class="corner">
                {poolEntry?.pool?.category === 'game_result' ? 'Full Result' : ''}
                {poolEntry?.pool?.category === 'total_goals' ? 'Goals' : ''}
                {poolEntry?.pool?.category === 'total_corners' ? 'Corners' : ''}
                {poolEntry?.pool?.category === 'total_cards' ? 'Cards' : ''}
                <span>(<img src={cornerIco} alt="" /> <span className='text-white'>6</span>/32)</span></div>
            <div class="r1">
                <div class="text">BET</div>
                <div class="number" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    {poolEntry?.pool?.category === 'game_result' ? poolEntry?.result ? poolEntry?.result?.name : 'Draw' : ''}
                    {(poolEntry?.pool?.category === 'total_goals') || (poolEntry?.pool?.category === 'total_corners') || (poolEntry?.pool?.category === 'total_cards') ? 
                    <>
                    <img style={{height: '11px', marginRight: '5px'}} src={poolEntry?.result_other === 'over' ? arrowUp : arrowDown} alt="" /> {poolEntry?.pool[poolEntry?.pool?.category]}
                    </>
                    : ''}
                </div>
            </div>
            <div class="r1 mb-3">
                <div class="text">AMOUNT</div>
                <div class="TEXT">{poolEntry?.pool?.points}</div>
            </div>
            <button className="themeBtn" 
            style={((new Date(poolEntry?.game?.endTime) && !!poolEntry?.output) < new Date()) ? {
                color: getColor(poolEntry?.output) 
            } : {}}
            onClick={() => navigate(`/game/${poolEntry?.game?._id}`, {state: {type: poolEntry?.pool?.type}})}>
                {(new Date(poolEntry?.game?.endTime) < new Date()) ? (
                <>
                {poolEntry?.output ? (
                    <>{poolEntry?.output}</>
                ): (
                    <>Not Ended Yet</>
                )}
                </>) : (
                <><i className="fa-solid fa-plus"></i> Add More</>
                )}
            </button>
        </div>
    </div>
  )
}
export const BettCard2 = ({game, upcoming}) => {
  const navigate = useNavigate();
  return (
    <div className="betCardMain ">
        <div className="betCard ">
            <div className="team">
                <div className="imgDiv">
                    <img src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/${game?.teamA?.img}`} alt="" />
                </div>
                <div className="title">{game.teamA.name}</div>
            </div>
            {/* {upcoming ? (
                <div className="score">
                    <div className="time">{Math.round((((new Date().getTime() - new Date(game?.startTime)) ) ) / 60000) > 90 ? '90' : Math.round((((new Date().getTime() - new Date(game?.startTime)) ) ) / 60000)}’</div>
                </div>
            ) : ( */}
                <div className="score">
                    <div className="count">{game?.teamAGoals}-{game?.teamBGoals}</div>
                    {/* <div className="time"> */}
                        {/* {Math.round((((new Date().getTime() - new Date(game?.startTime)) ) ) / 60000) > 90 ? '90' : Math.round((((new Date().getTime() - new Date(game?.startTime)) ) ) / 60000)}’ */}
                        {new Date(game?.startTime) > new Date() ? 
                        <div className="time" style={{color: 'grey'}}>
                        {`${months[new Date(game?.startTime).getMonth()]} ${new Date(game?.startTime).getDate()}, ${new Date(game?.startTime).getHours().toString().padStart(2, '0')}:${new Date(game?.startTime).getMinutes().toString().padStart(2, '0')}`}
                        </div>
                        : ''}
                        {new Date(game?.endTime) < new Date() ? <div className="time" style={{color: 'red'}}>Ended</div> : ''}
                        {(new Date(game?.endTime) > new Date()) && (new Date(game?.startTime) < new Date) ? <div className="time">{Math.round((((new Date().getTime() - new Date(game?.startTime)) ) ) / 60000)}</div> : ''}
                    {/* </div> */}
                </div>
            {/* )} */}
            <div className="team">
                <div className="imgDiv">
                    <img src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/${game?.teamB.img}`} alt="" />
                </div>
                <div className="title">{game?.teamB.name}</div>
            </div>
        </div>
        <div className="btnMain">
            <div className="btnRow">
                {new Date(game?.endTime) < new Date() ? (
                    <>
                    <div onClick={() => navigate("/game/"+game._id, {state: {type: 'pvp'}})} className="themeBtn me-2">View Details</div>
                    </>
                ) : (
                    <>
                        <div onClick={() => navigate("/game/"+game._id, {state: {type: 'pvp'}})} className="themeBtn me-2"> Heads up</div>
                        <div onClick={() => navigate("/game/"+game._id, {state: {type: 'rumble'}})} className="themeBtnOutline"> Rumble</div>                    
                    </>
                )}
            </div>
        </div>
    </div>
  )
}
