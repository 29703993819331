import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useSelector } from "react-redux";
import { showToast } from "../../App";

export default function TeamSection({teams, setTeams}){
  const [teamName, setTeamName] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [img, setImg] = useState("");
  const authedAdmin = useSelector(s => s.authedAdmin);

  const addTeam = async () => {
    console.log(localStorage.getItem('admin'));
    if (!teamName){
      showToast('Team name is required');
      return;
    }
    if (!img){
      showToast('Team Image is required');
      return;
    }
    if (Object.keys(authedAdmin.authedAdmin) === 0){
      showToast('Please login');
      return;
    }
    let fd = new FormData();
    fd.append('team', teamName);
    fd.append('img', img, img.name);
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/teams`, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('admin')
      },
      body: fd
    }).then(r => {
      if (r.ok){return r.json()}else{
        return {success: false, msg: 'Failed'};
      }
    }).then(res => {
      if (res.success){
        setTeams(teams.concat([res.team]));
      }
      else{
        showToast(res.msg);
      }
    });
    setModalShow(false);
  }
  
  return(
    <div>
      <div className="createBetBtn">
        <button className="themeBtn" onClick={()=>setModalShow(true)}>Add Teams</button>
      </div>
      <div className="table-responsive mt-4 homeTable">

      <table className="table custom-table">
        <thead>
          <tr>
            <td scope="col">Team Image</td>
            <td scope="col">Team Name</td>
          </tr>
        </thead>
        <tbody>
          {teams.map(team => (
            <tr key={team._id}>
              <td><img height={'60'} src={`${process.env.REACT_APP_SERVER_BASE_URL}/images/${team.img}`} /></td>
              <td>{team.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="betModal">
            <div className="closeIcon" onClick={() => setModalShow(false)}>
              <i className="fas fa-times"></i>
            </div>
            <form className="m-signin-form" onSubmit={(e) => e.preventDefault() }>
              <h2 className="text-center">Create Bet  </h2>
                <div className="form-group">
                  <input
                    className="text"
                    onChange={(e) => setTeamName(e.target.value)}
                    type="text"
                    name="teamName"
                    placeholder="Team Name"
                  />
                </div>
                <div className="form-group">
                <input
                    className="text"
                    onChange={(e)=> setImg(e.target.files[0])}
                    type="file"
                    name="teamImg"
                    placeholder="image"
                    accept="image/*"
                  />
                </div>
                <div className="text-center m-signin-submit">
                  <button onClick={addTeam} className="btn">
                    Add
                  </button>
                </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}