import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import showPassImg from '../../assets/images/showPass.svg';
import hidePass from '../../assets/images/hidePass.svg';
import logo from '../../assets/images/logo.png';
// import google from '../../assets/images/google.png';
import twitter from '../../assets/images/twitter.png';
import emailIco from '../../assets/images/emailIco.svg';
import passwordIco from '../../assets/images/passwordIco.svg';
import { useDispatch } from "react-redux";
import { getUserBalance, loginUser } from "../../redux/ActionCreators";
import { showToast } from "../../App";

export default function Login({setSignupModal,setShowLogin}){
  const [showLoginModal, setShowLoginModal] = useState(false);
	const [forgetPassModal, setForgetPassModal] = useState(false);
  const [showPass, setShowPass] = useState(false);
	
	const dispatch = useDispatch();

	const handleShowForgetModal =()=>{
		setShowLoginModal(false)
		setForgetPassModal(true)
	}
	const login = async () => {
		let email = document.getElementById('sign-in-email').value;
		let pwd = document.getElementById('sign-in-pwd').value;
		var expression = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
		if (expression.test(email)){
			fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/login`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email: email, pwd: pwd })
			}).then(r => r.json())
			.then(res => {
				if (res.success){
					res.user.token = res.tok;
					dispatch(loginUser(res.user));
					dispatch(getUserBalance(res.user));
          setShowLoginModal(false)
				}
				else{
					showToast(res.msg);
				}
			})			
		}
		else{
			showToast('Invalid email address');
		}
	}
	
  return(
    <>
		<div>
			<div>
				<div className="inputMain">
					<img className="ico" src={emailIco} alt="" />
					<input type="text" id="sign-in-email" style={{color: 'white'}} placeholder="Email..." />
				</div>
				<div className="inputMain">
					<img className="ico" src={passwordIco} alt="" />
					<div className="passInput w-100">
						<input type={showPass?"text":"password"} style={{color: 'white'}} id="sign-in-pwd" placeholder="Password..." />
						{showPass?
						<img onClick={()=>setShowPass(false)} src={showPassImg} alt="" />
						:
						<img onClick={()=>setShowPass(true)} src={hidePass} alt="" />
						}
					</div>
				</div>
				<div className="mb-2">
					<label onClick={()=>handleShowForgetModal()} className="forget-pass cursor-pointer" >Forget Password</label>
				</div>
				<div className="btnDiv my-3">
					<button className="themeBtn w-100" onClick={login}>
						Login
					</button>
				</div>
				<div className="or">
					<span></span>
					<div className="px-2">Or</div>
					<span></span>
				</div>
				{/* <div className="inputMain">
							<img src={google} alt="" className="ico me-3" />
							<div className="text-center w-100">
								Continue with Google
							</div>
				</div> */}
				<div className="inputMain logWith">
					<img src={twitter} alt="" className="ico me-3" />
					<div className="text-center w-100">
						Continue with X
					</div>
				</div>
				<div className="already">Dont have an account? <span onClick={()=>setShowLogin(false)}>Sign Up</span></div>
			</div>
			<div className="closeIcon" onClick={() => setSignupModal(false)}>
			<i class="fas fa-times"></i>
			</div>
		</div>
			   
      
			<Modal id="WalletModal" className="signModal" show={forgetPassModal} onHide={()=> setForgetPassModal(false)} centered >
				<Modal.Body>
					<div >
					<div className="logo"><img className="img-fluid" src={logo} alt="" /></div>
					<div >
						<div className="inputMain">
							<i className="fa-solid fa-envelope ico" />
							<input type="email" id="forget-email" placeholder="Email..." />
						</div>
						<div className="btnDiv mt-4">
							<button className="themeBtn w-100" >
								Submit
							</button>
						</div>
					</div>
					<div className="closeIcon" onClick={() => setForgetPassModal(false)}>
					<i class="fas fa-times"></i>
					</div>
					</div>
				</Modal.Body>
			</Modal>
    </>
  );
}