import * as ActionTypes from './ActionTypes';
import axios from "axios";

export const loginUser = (u) => ({
  type: ActionTypes.LOGGED_IN,
  payload: u
});

export const updateUser = (u) => ({
  type: ActionTypes.UPDATE_USER,
  payload: u
});

export const logoutUser = (u) => ({
  type: ActionTypes.LOGGED_OUT,
});

export const loginAdmin = (u) => ({
  type: ActionTypes.LOGGED_IN_ADMIN,
  payload: u
});
export const logoutAdmin = (u) => ({
  type: ActionTypes.LOGGED_OUT_ADMIN,
  payload: u
});

export const getUserBalance = (u) => (dispatch) => {
  return fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/get/points-userId`, {
    method:'GET',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }).then(r => r.ok ? r.json() : ({success:false, points: 0}))
  .then((r) => {
    return dispatch(updateUser({...u, points: r?.points ? r.points.reduce((accumulator, object) => {
      return accumulator + object.points;
    }, 0) : 0}))
  })
}

export const setGames = (g) => ({
  type: ActionTypes.SET_GAMES,
  payload: g
})

export const updateGame = (g) => ({
  type: ActionTypes.UPDATE_GAME,
  payload: g
})

export const removeGames = (g) => ({
  type: ActionTypes.REMOVE_GAMES,
  payload: g
})

export const getGames = () => (dispatch) => {
  return fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/games`)
  .then(r => r.ok ? r.json() : ({success:false, games: []}))
  .then((r) => {
    if (r.success){
      return dispatch(setGames(r.games.sort((a, b) => new Date(a.endTime) - new Date(b.endTime))));
    }
  })
}