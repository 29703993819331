import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { showToast } from '../../App';
import { Form } from 'react-bootstrap';

export default function GameRow({game, setGames}){
  let [pools, setPools] = useState([]);
  let [teamAGoals, setTeamAGoals] = useState(0);
  let [teamBGoals, setTeamBGoals] = useState(0);
  let [modalShow, setModalShow] = useState(false);
  let [modalShowPoolOptions, setModalShowPoolOptions] = useState(false);
  let [resolveModalShow, setResolveModalShow] = useState(false);
  let [valuesModalShow, setValuesModalShow] = useState(false);
  let [timeModal, setTimeModal] = useState(false);
  
  const getDetail = async () => {
    let res = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/get-pools/${game._id}`, {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('admin'),
      }
    }).then(r => r.ok ? r.json() : {});
    if (res.success){
      setPools(res.pools.map((p, i) => {p.entries = res.entriesArray[i]; return p;}));
    }
  }

  const resolveGame = async () => {
    let res = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/resolve-pools`, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('admin'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 
        game_id: game._id,
        goalA: `goals_${game?.teamAGoals ? game?.teamAGoals : '0'}`, 
        goalB: `goals_${game?.teamBGoals ? game?.teamBGoals : '0'}`, 
        corners: `corners_${game?.corners ? game?.corners : '0'}`, 
        cards: `cards_${game?.cards ? game?.cards : '0'}` 
      })
    }).then(r => r.ok ? r.json() : {success: false, msg: 'Failed'});
    if (res.success){
      setResolveModalShow(false);
      setGames((games) => games.map(g => g._id === res.game._id ? res.game : g));
      showToast('Success');
    }
    else{
      showToast(res.msg);
    }
  }
  
  const updateTime = () => {
    let startTime = document.getElementById('start-time-update').value;
    let endTime = document.getElementById('end-time-update').value;
    if (!startTime){
      showToast('Please select Start Time'); return;
    }
    if (!endTime){
      showToast('Please select End Time'); return;
    }
    startTime = new Date(startTime); endTime = new Date(endTime);
    let d = new Date();
    if (d > endTime){
      showToast('End Time is in past'); return;
    }
    if (endTime < startTime){
      showToast('End Time is earlier than start time'); return;
    }
    startTime = startTime.toISOString(); endTime = endTime.toISOString();
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/update-time`, {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('admin'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({endTime, startTime, game_id: game._id})
    }).then(r => {
      if (r.ok){return r.json()}else{
        return {success: false, msg: 'Failed'};
      }
    }).then(res => {
      if (res.success){
        showToast('Success');
        setGames((games) => games.map(g => g._id === res.game._id ? res.game : g));
      }
      else{
        showToast(res.msg);
      }
    });
    setTimeModal(false);
  }

  useEffect(() => {
    getDetail();
  }, []);

  const addPoolOption = (type) => {
    let val;
    if (type === 'goals'){
      val = document.getElementById('goal-option-val').value;
    }
    if (type === 'corners'){
      val = document.getElementById('corner-option-val').value;
    }
    if (type === 'points'){
      val = document.getElementById('point-option-val').value;
    }
    if (type === 'cards'){
      val = document.getElementById('card-option-val').value;
    }
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/game-pool-options`, {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('admin'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ game_id: game._id, type, val })
    }).then(r => r.ok ? r.json() : {success: false, msg: 'Failed'})
    .then(r => {
      if (r.success){
        showToast('Success');
        setGames((games) => games.map(g => g._id === r.game._id ? r.game : g));
      }
      else{
        showToast(r.msg);
      }
    });
  }

  const setValuesGame = () => {

    let total_corners = document.getElementById(`set-value-corners-${game?._id}`).value;
    let total_cards = document.getElementById(`set-value-cards-${game?._id}`).value;
    let teamAGoals = document.getElementById(`set-value-teama-${game?.teamA?._id}`).value;
    let teamBGoals = document.getElementById(`set-value-teamb-${game?.teamB?._id}`).value;

    total_corners = total_corners ? total_corners : '0';
    total_cards = total_cards ? total_cards : '0';
    teamAGoals = teamAGoals ? teamAGoals : '0';
    teamBGoals = teamBGoals ? teamBGoals : '0';

    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/set-values`, {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('admin'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({teamAGoals, teamBGoals, total_corners, total_cards, game_id: game._id})
    })
    .then(r => r.json())
    .then(r => {
      if (r.success){
        showToast('Success');
        setGames((games) => games.map(g => g._id === r.game._id ? r.game : g));
      }
      else{
        showToast(r.msg);
      }
    })

  };
  return(
    <>
      <tr key={game._id}>
        <td>{game.teamA.name}</td>
        <td>{game.teamB.name}</td>
        <td>{pools.length}</td>
        <td>{game?.ended ? 'Resolved' : <button onClick={() => setValuesModalShow(true)}>Set Values</button>}</td>
        <td>{game?.ended ? 'Resolved' : <button onClick={() => setTimeModal(true)}>Edit</button>}</td>
        <td><button onClick={() => setModalShow(true)}>View Details</button></td>
        <td>{game?.ended ? 'Resolved' : <button onClick={() => setResolveModalShow(true)}>Resolve</button>}</td>
        <td>{game?.ended ? 'Resolved' : <button onClick={() => setModalShowPoolOptions(true)}>Pool Options</button>}</td>
      </tr>
      <Modal
          show={modalShow}
          onHide={() => setModalShow(false)} 
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
        <Modal.Body>
          <div className="betModal">
            <div className="closeIcon" onClick={() => setModalShow(false)}>
              <i className="fas fa-times"></i>
            </div>
            <form className="m-signin-form" onSubmit={(e) => e.preventDefault() }>
              <h2 className="text-center">Game Details </h2>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <h4>Total Pools</h4>
                  <div className='text-white'>{pools.length}</div>
                </div>
                {pools.map((pool, i) => (
                  <PoolDetailSection i={i+1} game={game} pool={pool} key={pool._id} />
                ))}
            </form>
          </div>
        </Modal.Body>
      </Modal>
      
      <Modal
        show={resolveModalShow}
        onHide={() => setResolveModalShow(false)} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <Modal.Body>
        <div className="betModal">
            <div className="closeIcon" onClick={() => setResolveModalShow(false)}>
            <i className="fas fa-times"></i>
            </div>
          <form className="m-signin-form" onSubmit={(e) => e.preventDefault() }>
            <h2 className="text-center">Resolve Game  </h2>
              {/* <div className="form-group">
                <input
                  onChange={onInputChange}
                  name="betType"
                  className="text"
                  placeholder="betType"
                />
              </div> */}
              <div className="form-group">
                <label>Goals of {game?.teamA?.name} (Team A)</label><br/>
                <input
                  className="text"
                  type="number"
                  step="any"
                  value={game?.teamAGoals ? game?.teamAGoals : '0'}
                />
              </div>
              <div className="form-group">
              <label>Goals of {game?.teamB?.name} (Team B)</label><br/>
                <input
                  className="text"
                  value={game?.teamBGoals ? game?.teamBGoals : '0'}
                  type="number"
                />
              </div>
              <div className="form-group">
              <label>Total Corners</label><br/>
                <input
                  className="text"
                  value={game?.corners ? game?.corners : '0'}
                  type="number"
                />
              </div>
              <div className="form-group">
              <label>Total Cards</label><br/>
                <input
                  className="text"
                  value={game?.cards ? game?.cards : '0'}
                  type="number"
                />
              </div>
              <div className="text-center m-signin-submit">
                <button onClick={resolveGame} className="btn">
                  Resolve
                </button>
              </div>

          </form>
        </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={valuesModalShow}
        onHide={() => setValuesModalShow(false)} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <Modal.Body>
        <div className="betModal">
            <div className="closeIcon" onClick={() => setValuesModalShow(false)}>
            <i className="fas fa-times"></i>
            </div>
          <form className="m-signin-form" onSubmit={(e) => e.preventDefault() }>
            <h2 className="text-center">Set Values</h2>
              {/* <div className="form-group">
                <input
                  onChange={onInputChange}
                  name="betType"
                  className="text"
                  placeholder="betType"
                />
              </div> */}
              <div className="form-group">
                <label>Goals of {game?.teamA?.name} (Team A)</label><br/>
                <input
                  className="text"
                  type="number"
                  placeholder={`Goals of ${game?.teamA?.name} (Team A)`}
                  step="any"
                  defaultValue={game?.teamAGoals ? game?.teamAGoals : 0}
                  id={`set-value-teama-${game?.teamA?._id}`}
                />
              </div>
              <div className="form-group">
              <label>Goals of {game?.teamB?.name} (Team B)</label><br/>
                <input
                  className="text"
                  id={`set-value-teamb-${game?.teamB?._id}`}
                  type="number"
                  defaultValue={game?.teamBGoals ? game?.teamBGoals : 0}
                  placeholder={`Goals of ${game?.teamB?.name} (Team B)`}
                />
              </div>
              
              <div className="form-group">
              <label>Total Corners</label><br/>
                <input
                  className="text"
                  id={`set-value-corners-${game?._id}`}
                  type="number"
                  step="0.1"
                  defaultValue={game?.corners ? game?.corners : 0}
                  placeholder={`Corners of Game`}
                />
              </div>
              <div className="form-group">
              <label>Total Cards</label><br/>
                <input
                  className="text"
                  id={`set-value-cards-${game?._id}`}
                  type="number"
                  step="0.1"
                  defaultValue={game?.cards ? game?.cards : 0}
                  placeholder={`Cards of Game`}
                />
              </div>
              <div className="text-center m-signin-submit">
                <button onClick={setValuesGame} className="btn">
                  Set
                </button>
              </div>

          </form>
        </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalShowPoolOptions}
        onHide={() => setModalShowPoolOptions(false)} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <Modal.Body>
        <div className="betModal">
            <div className="closeIcon" onClick={() => setModalShowPoolOptions(false)}>
            <i className="fas fa-times"></i>
            </div>
          <form className="m-signin-form" onSubmit={(e) => e.preventDefault() }>
            <h2 className="text-center">Pool Options</h2>
              <div className="form-group">
                <label>Total Corners Options</label><br/>
                <input
                  className="text"
                  type="number"
                  id="corner-option-val"
                  step="0.1"
                  placeholder={`Corners Options`}
                />
                <div>Current Options: {game.cornerOptions?.join(',')}</div>
              </div>
              <div className="text-center m-signin-submit">
                <button onClick={() => addPoolOption('corners')} className="btn">
                  Add
                </button>
              </div>
              <div className="form-group">
                <label>Total Goals Options</label><br/>
                <input
                  className="text"
                  type="number"
                  id="goal-option-val"
                  placeholder={`Goals Options`}
                />
                <div>Current Options: {game.goalsOptions?.join(',')}</div>
              </div>
              <div className="text-center m-signin-submit">
                <button onClick={() => addPoolOption('goals')} className="btn">
                  Add
                </button>
              </div>
              <div className="form-group">
                <label>Total Points Options</label><br/>
                <input
                  className="text"
                  type="number"
                  id="point-option-val"
                  placeholder={`Goals Options`}
                />
                <div>Current Options: {game.pointsOptions?.join(',')}</div>
              </div>
              <div className="text-center m-signin-submit">
                <button onClick={() => addPoolOption('points')} className="btn">
                  Add
                </button>
              </div>
              <div className="form-group">
                <label>Total Cards Options</label><br/>
                <input
                  className="text"
                  type="number"
                  step="0.1"
                  id="card-option-val"
                  placeholder={`Cards Options`}
                />
                <div>Current Options: {game.cardsOptions?.join(',')}</div>
              </div>
              <div className="text-center m-signin-submit">
                <button onClick={() => addPoolOption('cards')} className="btn">
                  Add
                </button>
              </div>
          </form>
        </div>
        </Modal.Body>
      </Modal>

      
      <Modal
        show={timeModal}
        onHide={() => setTimeModal(false)} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="betModal">
            <div className="closeIcon" onClick={() => setTimeModal(false)}>
              <i className="fas fa-times"></i>
            </div>
            <form className="m-signin-form" onSubmit={(e) => e.preventDefault() }>
              <h2 className="text-center">Edit Time ({game?.teamA?.name} vs {game?.teamB?.name})  </h2>
                <div className="row">
                  <div className="col-6 form-group mt-2">
                    <label>Start Time:</label><br/>
                    <input id="start-time-update" type="datetime-local" />
                  </div>
                  <div className="col-6 form-group mt-2">
                    <label>End Time:</label><br/>
                    <input id="end-time-update" type="datetime-local" />
                  </div>
                </div>
                <div className="text-center m-signin-submit mt-3">
                  <button onClick={updateTime} className="btn">
                    Update
                  </button>
                </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const PoolDetailSection = ({pool, game, i}) => {
  return(
    <>
      <hr/>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Pool # {i}</h4>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Pool Type</h4>
        <div className='text-white'>{pool.type}</div>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Pool Category</h4>
        <div className='text-white'>
          {pool.category === 'total_goals' ? 'Total Goals' : ''}
          {pool.category === 'total_corners' ? 'Total Corners' : ''}
          {pool.category === 'total_cards' ? 'Total Cards' : ''}
          {pool.category === 'game_result' ? 'Full Time Result' : ''}
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Points per Entry</h4>
        <div className='text-white'>{pool.points}</div>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h4>Total Entries</h4>
        <div className='text-white'>{pool.entries.length}</div>
      </div>
      {pool.category === 'total_corners' ? (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h4>Corners:</h4>
          <div className='text-white'>{pool.total_corners}</div>
        </div>
      ) : (<></>)}
      {pool.category === 'total_cards' ? (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h4>Cards:</h4>
          <div className='text-white'>{pool.total_cards}</div>
        </div>
      ) : (<></>)}      
      {pool.category === 'total_goals' ? (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h4>Goals:</h4>
          <div className='text-white'>{pool.total_goals}</div>
        </div>
      ) : (<></>)}

      {pool.category === 'game_result' ? (<>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h4>Entries for {game.teamA?.name}:</h4>
          <div className='text-white'>{pool.entries.filter(e => e.result === game.teamA?._id).length}</div>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h4>Entries for {game.teamB?.name}:</h4>
          <div className='text-white'>{pool.entries.filter(e => e.result === game.teamB?._id).length}</div>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h4>Entries for Draw:</h4>
          <div className='text-white'>{pool.entries.filter(e => !e.result).length}</div>
        </div>
      </>) : (<></>)}
      {pool.category === 'total_goals' || pool.category === 'total_corners' || pool.category === 'total_cards' ? (<>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h4>Entries for Under:</h4>
          <div className='text-white'>{pool.entries.filter(e => e.result_other === 'under').length}</div>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h4>Entries for Over:</h4>
          <div className='text-white'>{pool.entries.filter(e => e.result_other === 'over').length}</div>
        </div>
      </>) : (<></>)}
    </>
  );
}
