import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { showToast } from "../../App";

export default function WithdrawEthSection({ sdBalance, getEthBalance, chain }){
  const authedUser = useSelector(s => s.authedUser);
  const [maxWithdrawAmount, setMaxAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [canWithdraw, setCanWithdraw] = useState(0);
  const [maxAmt, setMaxAmt] = useState(0);

  async function pasteText(){
    const text = await navigator.clipboard.readText()
    document.getElementById('external-wallet').value = text;
  }
  
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/get-max-withdraw/${chain}`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(r => r.ok ? r.json() : {success: false}).then(r => {
      setFetching(false);
      if (r.success){
        setMaxAmt(r.amount)
      }
      else{
        if (r.msg){
          showToast(r.msg);
        }
        else{
          showToast('Failed to load balance');
        }
      }
    });
  }, [sdBalance]);

  const CanMaxWithdrawEth = async () => {
    setLoading(true);
    let r = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/get-max-withdraw/${chain}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }).then(r => r.json());
    setLoading(false);
    return r.amount
  }
  const withdrawEth = async () => {
    try{
        let maxWithdraw = await CanMaxWithdrawEth()
        if(maxWithdrawAmount > maxWithdraw ){
            showToast(`Max withdrawable amount is ${maxWithdraw} ETH`);
            setMaxAmount(maxWithdraw)
            // handleWithdrawShow()
            return 
        }
        if (maxWithdrawAmount > 0){
            let address = document.getElementById('external-wallet').value;
            if (!address){
                showToast('Address is required');
                return;
            }
            setLoading(true);
            let r = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/users/withdraw-eth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ amt: maxWithdrawAmount, address, chain })
            }).then(r => r.json());
            if (r.success){
                setMaxAmount(0);
                setLoading(false);
                showToast('Done. '+r.hash);
                getEthBalance();
            }
            else{
                setLoading(false);
                showToast(`Failed: ${r.msg}`);
            }
        }
        else{
            showToast('Cant withdraw '+maxWithdrawAmount+' ETH');
        }
    }
    catch(e){
        console.log(e);
        setLoading(false);
        showToast('Failed');
    }
  }
  return(
    <>
      <div className="heading my-2">External Wallet</div>
      <div className="sliderMain">
        <div className="inputRow">
          <div className="col1">
            <div className="inputM mb-2">
              <input type="text" name="" id="" value={maxWithdrawAmount} onChange={e => setMaxAmount(e.target.value)}  placeholder="Amount" />
              <div className="balance">Balance: <span>{fetching ? 'Loading...' : sdBalance.substring(0,7)}</span> ETH </div>
            </div>
            <div className="col2">
              <div className="fil" onClick={() => {console.log("1", maxAmt, maxAmt*0.25);setMaxAmount(0.25*maxAmt)}}>25%</div>
              <div className="fil" onClick={() => {console.log("11", maxAmt, maxAmt*0.5);setMaxAmount(0.5*maxAmt)}}>50%</div>
              <div className="fil" onClick={() => {console.log("111", maxAmt,  maxAmt*0.75);setMaxAmount(0.75*maxAmt)}}>75%</div>
              <div className="fil" onClick={() => setMaxAmount(maxAmt)}>Max</div>
            </div>
            <div className="inputM mb-2">
              <input type="text" name="" id="external-wallet" placeholder="External Wallet Address" />
              <div className="balance" style={{cursor: 'pointer'}} onClick={pasteText}><span>Paste</span></div>
            </div>
            {/* <div className="inputM mb-2">
              <input type="text" name="" id="" value={authedUser?.authedUser?.sdAddress} placeholder="Stardust Wallet" />
              <div className="balance" style={{cursor: 'pointer'}} onClick={() => copyText(authedUser?.authedUser?.sdAddress)}><span>Copy</span></div>
            </div> */}
            <div className="sbtn">
              <button className="themeBtn" 
              onClick={loading ? () => {} : withdrawEth}
              >{loading ? 'Withdrawing...' : 'Submit'}</button>
            </div>
          </div>
       
        </div>
      </div>
    </>
  );
}