import React, { useEffect } from 'react'
import '../pool.css'
import { Modal } from 'react-bootstrap'
import PoolRow from './poolRow'

export const PoolAvailableFullModal = ({getPools, pools, show,setShow, game}) => {
  useEffect(() => {
    getPools()
  }, []);
  return (
    <Modal
    show={show}
    onHide={() => setShow(false)} 
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className='BModal'
    >
        <Modal.Body>
        <div className="betModal">
            <div className="closeIcon" onClick={() => setShow(false)}>
            <i className="fas fa-times"></i>
            </div>
           <h3><span>Available</span> Full Result Pools</h3>
           <section className='tableSec'>
              <div className="table-responsive">
              <table className="table table-striped mb-0">
                  <thead>
                      <tr>
                      <th scope="col"><div className="themeYellow">Pool Type</div></th>
                      <th scope="col">
                          <div className="themeYellow">Bet On</div>
                      </th>
                      <th scope="col">
                          <div className="themeYellow">Amount</div>
                      </th>
                      <th scope="col">
                          <div className="themeYellow">Total Bets</div>
                      </th>
                      <th scope="col">
                      </th>
                      </tr>
                  </thead>
                  <tbody>
                    {pools.map(p => {
                        return(
                            <PoolRow getPools={getPools} key={p._id} p={p} game={game} />
                        );
                    })}

                      {/* <tr>
                            <td scope="row"><div className="inner">
                                <div className="textStyle"><img className='arrow' src={arrowUp} alt="" /> Up Pool</div>
                            </div></td>
                            <td><div className="inner"><div className="textStyle"><img className='arrow' src={arrowUp} alt="" /> 6.5</div></div></td>
                            <td><div className="inner"><div className="textStyle">500 Points</div></div></td>
                            <td>
                                <div className="inner text-center">
                                <button className='themeBtn1'>Enter</button>  
                                </div>
                            </td>
                      </tr>
                      <tr>
                            <td scope="row"><div className="inner"><div className="textStyle"><img className='arrow' src={arrowDown} alt="" /> Up Pool</div></div></td>
                            <td><div className="inner"><div className="textStyle"><img className='arrow' src={arrowDown} alt="" /> 6.5</div></div></td>
                            <td><div className="inner"><div className="textStyle">500 Points</div></div></td>
                            <td>
                                <div className="inner text-center">
                                <button className='themeBtn1'>Enter</button>  
                                </div>
                            </td>
                      </tr>
                      <tr>
                            <td scope="row"><div className="inner">
                                <div className="textStyle"><img className='arrow' src={arrowUp} alt="" /> Up Pool</div>
                            </div></td>
                            <td><div className="inner"><div className="textStyle"><img className='arrow' src={arrowUp} alt="" /> 6.5</div></div></td>
                            <td><div className="inner"><div className="textStyle">500 Points</div></div></td>
                            <td>
                                <div className="inner text-center">
                                <button className='themeBtn1'>Enter</button>  
                                </div>
                            </td>
                      </tr>
                      <tr>
                            <td scope="row"><div className="inner">
                                <div className="textStyle"><img className='arrow' src={arrowUp} alt="" /> Up Pool</div>
                            </div></td>
                            <td><div className="inner"><div className="textStyle"><img className='arrow' src={arrowUp} alt="" /> 6.5</div></div></td>
                            <td><div className="inner"><div className="textStyle">500 Points</div></div></td>
                            <td>
                                <div className="inner text-center">
                                <button className='themeBtn1'>Enter</button>  
                                </div>
                            </td>
                      </tr>
                      <tr>
                            <td scope="row"><div className="inner">
                                <div className="textStyle"><img className='arrow' src={arrowUp} alt="" /> Up Pool</div>
                            </div></td>
                            <td><div className="inner"><div className="textStyle"><img className='arrow' src={arrowUp} alt="" /> 6.5</div></div></td>
                            <td><div className="inner"><div className="textStyle">500 Points</div></div></td>
                            <td>
                                <div className="inner text-center">
                                <button className='themeBtn1'>Enter</button>  
                                </div>
                            </td>
                      </tr>
                      <tr>
                            <td scope="row"><div className="inner">
                                <div className="textStyle"><img className='arrow' src={arrowUp} alt="" /> Up Pool</div>
                            </div></td>
                            <td><div className="inner"><div className="textStyle"><img className='arrow' src={arrowUp} alt="" /> 6.5</div></div></td>
                            <td><div className="inner"><div className="textStyle">500 Points</div></div></td>
                            <td>
                                <div className="inner text-center">
                                <button className='themeBtn1'>Enter</button>  
                                </div>
                            </td>
                      </tr> */}
                   
                    
                  </tbody>
                  </table>
              </div>
          </section>
        </div>
        </Modal.Body>
    </Modal>
  )
}
