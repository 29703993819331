import * as ActionTypes from '../ActionTypes';

export const Games = (state = [], action) => {
  switch(action.type) {
    case ActionTypes.SET_GAMES:
      return action.payload
    case ActionTypes.UPDATE_GAME:
     return state.map(g => g._id === action.payload._id ? action.payload : g)
    case ActionTypes.REMOVE_GAMES:
      return []
    default:
      return state;
  }
};