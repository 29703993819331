import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useSelector } from "react-redux";
import GameRow from "./GameRow";
import { showToast } from "../../App";
import HotGameModal from "./hotGameModal";

export default function GameSection({teams, setTeams, games, setGames}){
  const [modalShow, setModalShow] = useState(false);
  const [hotGameModal, setHotGameModal] = useState(false);
  const authedAdmin = useSelector(s => s.authedAdmin);

  const addGame = async () => {
    if (Object.keys(authedAdmin.authedAdmin) === 0){
      showToast('Please login'); return;
    }
    let teamA = document.getElementById('team-a').value;
    let teamB = document.getElementById('team-b').value;
    let startTime = document.getElementById('start-time').value;
    let endTime = document.getElementById('end-time').value;
    if (!teamA){
      showToast('Please Select Team A'); return;
    }
    if (!teamB){
      showToast('Please Select Team B'); return;
    }
    if (teamA === teamB){
      showToast('Team A and Team B should be different'); return;
    }
    if (!startTime){
      showToast('Please select Start Time'); return;
    }
    if (!endTime){
      showToast('Please select End Time'); return;
    }
    startTime = new Date(startTime); endTime = new Date(endTime);
    let d = new Date();
    if (d > startTime){
      showToast('Start time is in past'); return;
    }
    if (endTime < startTime){
      showToast('End Time is earlier than start time'); return;
    }
    startTime = startTime.toISOString(); endTime = endTime.toISOString();
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/games`, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('admin'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({endTime, startTime, teamA, teamB})
    }).then(r => {
      if (r.ok){return r.json()}else{
        return {success: false, msg: 'Failed'};
      }
    }).then(res => {
      if (res.success){
        setGames(games.concat([res.game]));
      }
      else{
        showToast(res.msg);
      }
    });
    setModalShow(false);
  }
  
  return(
    <div>
      <div className="createBetBtn">
        <button className="themeBtn" onClick={()=>setModalShow(true)}>Add Game</button>
        <button className="themeBtn" onClick={()=>setHotGameModal(true)}>Set Hot Game</button>
      </div>
      <div className="table-responsive mt-4 homeTable">

      <table className="table custom-table">
        <thead>
          <tr>
            <td scope="col">Team A</td>
            <td scope="col">Team B</td>
            <td scope="col">Total Pools</td>
            <td scope="col">Set Values</td>
            <td scope="col">Time</td>
            <td scope="col">Details</td>
            <td scope="col">Status</td>
            <td scope="col">Pool Options</td>
          </tr>
        </thead>
        <tbody>
          {games.map(game => (
            <GameRow game={game} setGames={setGames} key={`game-${game._id}-detail-row`} />
          ))}
        </tbody>
      </table>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="betModal">
            <div className="closeIcon" onClick={() => setModalShow(false)}>
              <i className="fas fa-times"></i>
            </div>
            <form className="m-signin-form" onSubmit={(e) => e.preventDefault() }>
              <h2 className="text-center">Create Game  </h2>
                <div className="form-group">
                  <label>Team A:</label><br/>
                  <select style={{width: '100%'}} className="mt-2" id="team-a">
                    {teams.map(team => (
                      <option value={team._id}>{team.name}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-2">
                  <label>Team B:</label><br/>
                  <select style={{width: '100%'}} className="mt-2" id="team-b">
                    {teams.map(team => (
                      <option value={team._id}>{team.name}</option>
                    ))}
                  </select>
                </div>
                <div className="row">
                  <div className="col-6 form-group mt-2">
                    <label>Start Time:</label><br/>
                    <input id="start-time" type="datetime-local" />
                  </div>
                  <div className="col-6 form-group mt-2">
                    <label>End Time:</label><br/>
                    <input id="end-time" type="datetime-local" />
                  </div>
                </div>
                <div className="text-center m-signin-submit mt-3">
                  <button onClick={addGame} className="btn">
                    Add
                  </button>
                </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <HotGameModal setModalShow={setHotGameModal} modalShow={hotGameModal} games={games} setGames={setGames} />
    </div>
  );
}