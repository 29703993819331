import React from 'react'
import '../pool.css'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { showToast } from '../../../App'
import { getUserBalance } from '../../../redux/ActionCreators'

export const CreatePoolModalCards = ({game,pools,getPools,setPools,type,show,setShow}) => {
    const authedUser = useSelector(s=>s.authedUser);
    const dispatch = useDispatch();
    const createPool = () => {
        if (Object.keys(authedUser.authedUser).length === 0){
            return showToast('Sign in');
        }
        
        let result = document.getElementById('create-pool-result-cards').value;
        let cards = document.getElementById('create-pool-type-cards').value;
        let pts = document.getElementById('create-pool-pts-cards').value;
        if (!result){
            showToast('Select Result');
        }
        if (!pts){
            showToast('Set points per entry');
        }
        fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/games/create-cards-pool`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ cards, result, points: pts, id: game._id, type })
        }).then(r => r.ok ? r.json() : {success:false, msg:'Failed'})
        .then(r => {
            console.log(r);
            if (r.success){
                setPools(pools.concat([r.pool]));
                showToast('Success');
                // if (type === 'pvp'){
                    dispatch(getUserBalance(authedUser.authedUser));
                    getPools();
                // }
            }
            else{
                showToast(r.msg);
            }
        })
    }

    return (
        <Modal
        show={show}
        onHide={() => setShow(false)} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='BModal'
        >
            <Modal.Body>
            <div className="betModal">
                <div className="closeIcon" onClick={() => setShow(false)}>
                <i className="fas fa-times"></i>
                </div>
                <form className="m-signin-form" onSubmit={(e) => e.preventDefault() }>
                    <h2 className="text-center mb-5">Create Pool (Cards)</h2>
                    <div className="createPoolRow">
                        {/* <div className="form-group me-4">
                            <label>Points per Entry:</label><hr/>
                            <input id='create-pool-pts-corners' type='number' style={{width: '100%'}} />
                        </div> */}
                        <div className="form-group mt-auto">
                            <select name="" id='create-pool-pts-cards' className="text select">
                                <option value="">Select Points</option>
                                {game?.pointsOptions?.map(o => (
                                    <option value={o}>{o}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group mt-auto">
                            <select name="" id='create-pool-result-cards' className="text select">
                                <option value="">Select Result</option>
                                <option value={'over'}>Over</option>
                                <option value={'under'}>Under</option>
                            </select>
                        </div>
                        <div className="form-group mt-auto">
                            <select name="" id='create-pool-type-cards' className="text select">
                                <option value="">Select Cards Option</option>
                                {game?.cardsOptions?.map(o => (
                                    <option value={o}>{o}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                
                    <div className="text-center continueBtn pb-4">
                        <button className="themeBtn1" onClick={createPool}>
                            Continue
                        </button>
                    </div>
                </form>
            </div>
            </Modal.Body>
        </Modal>
    )
}
